import React, { useState } from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";
import buildingPng from "../Assets/lgone.png";
import constructionPng from "../Assets/lgtwo (1).png";
import architectPng from "../Assets/lgthree.png";
import housePng from "../Assets/lgfour.png";
import { FormPageAbout } from "./Reusable/FormContactDetails";
import Modal from "@mui/material/Modal";
import { ThankYOUCard } from "./MethodStrongBuild";
import { useNavigate } from "react-router-dom";
import { PostCountANDevent } from "./Reusable/AxiosInstance";
import { Cookie } from "@mui/icons-material";
import Cookies from "js-cookie";
import useAnalytics from "../Component/CustomHook/useAnalytics";

const AboutUs = () => {
  const history = useNavigate();
  const { trackEvent } = useAnalytics();
  const [Drawer, setDrawer] = useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  return (
    <div>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
      >
        <div className="containerDesignw ">
          <ThankYOUCard
            handleClose={handleClose1}
            ViewClose={false}
            handleOpen1={handleOpen1}
          />
        </div>
      </Modal>
      <Navbar />
      <div className="bg-[#F3F2EF]">
        <div className="text-center px-3 ">
          <h1 className="text-3xl font-bold lg:text-[64px] lg:pt-5">
            Strong<span className="text-[#E98700]">Build</span>
          </h1>
          <p className="text-xs text-[#494847] leading-2 lg:text-2xl lg:leading-none lg:mt-10">
            Where Idea meets strength
          </p>
          <p className="mt-4 font-bold text-[#717171] text-sm lg:text-[36px]  lg:px-20 lg:leading-10 lg:font-[Play ]">
            A <span className="lg:text-[#E98700]"> platform </span>
            which offers you a wide range of options that you require for your{" "}
            <span className="lg:text-[#E98700]">construction project.</span>
          </p>
        </div>
        {/* --- container ---  */}
        <div className=" lg:flex lg:justify-center lg:items-center">
          <section className="border px-2 py-4 mt-4 mx-3 bg-white lg:p-10">
            <p className="text-[#E98700] italic mb-3 lg:text-[28px]">
              Looking to build? Look no further.
            </p>
            <p className="text-[#070707]">
              Our Platform connects you with our:
            </p>
            <div className="flex flex-col gap-y-3">
              <section className="flex items-center gap-2 mt-2">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.375 1.125H1.125V16.875H16.875V11.625"
                    stroke="#E98700"
                    stroke-width="2.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.625 8.625L9.375 12.375L18.375 2.625"
                    stroke="#E98700"
                    stroke-width="2.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>Architect Partners</p>
              </section>
              <section className="flex items-center gap-2">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.375 1.125H1.125V16.875H16.875V11.625"
                    stroke="#E98700"
                    stroke-width="2.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.625 8.625L9.375 12.375L18.375 2.625"
                    stroke="#E98700"
                    stroke-width="2.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>Construction Partners</p>
              </section>
              <section className="flex items-center gap-2">
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.375 1.125H1.125V16.875H16.875V11.625"
                    stroke="#E98700"
                    stroke-width="2.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.625 8.625L9.375 12.375L18.375 2.625"
                    stroke="#E98700"
                    stroke-width="2.25"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <p>Expert Advisors at every stage</p>
              </section>
              <p className="text-[#E98700] text-[.8rem] text-center mb-4">
                We take over the whole process and promise complete peace of
                mind at every stage.
              </p>
            </div>

            <div className="text-center">
              <button
                onClick={() => {
                  trackEvent("About_us", {
                    button_label: "About_us",
                  });
                  if (sessionStorage.getItem("CustomerID")) {
                    handleOpen1();
                    PostCountANDevent("About_us");
                  } else {
                    history("/otpForm");
                    Cookies.set("eventName", "About_us", {
                      expires: 1,
                      path: "/",
                    });
                  }
                }}
                className="border bg-[#333333] text-white shadow-md py-2 rounded-md px-3 font-[Lato]"
              >
                Connect with our experts Now!
              </button>
            </div>
          </section>
        </div>
      </div>

      {/* rahul code  */}
      <div className="px-2 flex flex-col gap-y-6 bg-[#F3F2EF] py-14">
        <div className="flex justify-center items-center lg:px-20">
          <div className="basis-2/4 ">
            <img src={buildingPng} alt="buildingPng" />
          </div>
          <div className="border basis-2/3 -ml-9 p-3 bg-white h-28 rounded-2xl shadow-lg lg:h-[26rem] lg:p-5">
            <h5 className="text-center text-[#494847] font-bold font-[Play] lg:text-[32px]">
              DESIGN
            </h5>
            <p className="text-[.5rem] text-[#717171] font-[Lato] lg:text-[20px] lg:hidden md:hidden">
              At StrongBuild, we pride ourselves on our innovative and practical
              approach to design. Our design team is committed to offering
              clients specialised solutions that fit their particular needs and
              budgetary limitations.
            </p>
            <p className="sm_hidden lg:text-[20px] lg:text-[#717171]">
              At StrongBuild, we pride ourselves on our innovative and practical
              approach to design. Our design team is committed to offering
              clients specialised solutions that fit their particular needs and
              budgetary limitations. At StrongBuild, we understand that each
              client is unique and has specific requirements. That's why we have
              a team of experienced designers who work closely with our clients
              to understand their needs, preferences, and budgetary limitations.
              We believe in providing practical and innovative solutions that
              not only meet but exceed our clients' expectations. Our design
              process is based on a comprehensive approach that considers all
              aspects of the project, from the initial concept to the final
              details. We utilize latest design software and tools to create
              high – quality 2D and 3D rendering, enabling clients to visualize
              the end results.
            </p>
          </div>
        </div>
        <div className="flex  lg:px-20 lg:my-10">
          <div className=" basis-2/3 border -mr-[3rem] z-40 bg-white  h-[7.4rem] rounded-2xl shadow-lg p-3 mt-[12.4px] lg:h-[26rem] lg:p-5 lg:mt-[36px]">
            <h5 className="text-center text-[#494847] font-bold font-[Play] lg:text-[32px]">
              CONSTRUCTION
            </h5>
            <p className="text-[.5rem] text-[#717171] font-[Lato] lg:hidden md:hidden">
              At StrongBuild, we keep our clients updated at every stage of the
              construction process because we feel that effective communication
              is essential to a project's success. We collaborate closely with
              clients to ensure their vision is realized.
            </p>
            <p className="sm_hidden lg:text-[20px] lg:text-[#717171]">
              At StrongBuild, we pride ourselves on our innovative and practical
              approach to design. Our design team is committed to offering
              clients specialised solutions that fit their particular needs and
              budgetary limitations. At StrongBuild, we understand that each
              client is unique and has specific requirements. That's why we have
              a team of experienced designers who work closely with our clients
              to understand their needs, preferences, and budgetary limitations.
              We believe in providing practical and innovative solutions that
              not only meet but exceed our clients' expectations. Our design
              process is based on a comprehensive approach that considers all
              aspects of the project, from the initial concept to the final
              details. We utilize latest design software and tools to create
              high – quality 2D and 3D rendering, enabling clients to visualize
              the end results.
            </p>
          </div>
          <div className="basis-2/4 z-30">
            <img src={constructionPng} alt="constructionPng" />
          </div>
        </div>
        <div>
          <div className="flex justify-center items-center lg:px-20">
            <div className="basis-2/4">
              <img src={architectPng} alt="architectPng" />
            </div>
            <div className="border basis-2/3 -ml-9 px-3 py-2 bg-white h-28 rounded-2xl shadow-lg lg:h-[26rem] lg:p-5">
              <h5 className="text-center text-[#494847] font-bold font-[Play] lg:text-[32px]">
                ARCHITECTS
              </h5>
              <p className="text-[.5rem] text-[#717171] font-[Lato] lg:hidden md:hidden">
                Our team of highly qualified and experienced architects at
                StrongBuild is committed to providing our clients with excellent
                results. Our architects collaborate closely with our
                construction team to satisfy all the client’s preferences.
              </p>
              <p className="sm_hidden lg:text-[20px] lg:text-[#717171]">
                Our team of highly qualified and experienced architects at
                StrongBuild is committed to providing our clients with excellent
                results. At StrongBuild, we understand that every project is
                unique and requires a personalized approach. That's why our team
                of architects takes the time to listen carefully to our clients'
                requirements and objectives, in order to gain a thorough
                understanding of their vision. We believe that effective
                communication is key to a successful project, so we work closely
                with our clients throughout the design process to ensure that
                their needs are fully met. Our architects are highly skilled and
                experienced, and they use the latest design tools and
                technologies to bring our clients' ideas to life. Our architects
                collaborate closely with our construction team to ensure that
                every detail of the design is executed flawlessly.
              </p>
            </div>
          </div>
        </div>
        <div className="flex lg:px-20">
          <div className=" basis-2/3 border -mr-[3rem] z-40 bg-white  h-[7.4rem] rounded-2xl shadow-lg p-3 mt-[12.4px] lg:h-[26rem] lg:p-5 lg:mt-[35px]">
            <h5 className="text-center text-[#494847] font-bold font-[Play] lg:text-[32px]">
              MODERN METHODS
            </h5>
            <p className="text-[.5rem] text-[#717171] font-[Lato] lg:hidden sm:hidden">
              We're committed to staying up-to-date with the latest trends and
              techniques in construction. We believe that in using modern
              construction practices to meet the unique needs of our clients. We
              also believe in promoting sustainability.
            </p>
            <p className="sm_hidden lg:text-[20px] lg:text-[#717171]">
              We're committed to staying up-to-date with the latest trends and
              techniques in construction. We believe that in using modern
              construction practices to meet the unique needs of our clients. We
              also believe in promoting sustainability. As a forward-thinking
              construction company, we understand the importance of staying
              ahead of the curve when it comes to the latest trends and
              techniques in the industry. By doing so, we can ensure that our
              clients receive the best possible service, and that our
              construction projects are executed with the highest level of
              precision and quality. We are also passionate about
              sustainability, and believe that the construction industry has a
              significant role to play in building a more sustainable future for
              our planet.
            </p>
          </div>
          <div className="basis-2/4 z-30">
            <img src={housePng} alt="housePng" />
          </div>
        </div>
      </div>
      <FormPageAbout Drawer={Drawer} setDrawer={setDrawer} cardClick={true} />
      <Footer />
    </div>
  );
};

export default AboutUs;
