import React, { useState } from "react";
import Strong_build from "../../Assets/Strong_build.svg";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import "./navbar.css";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Home from "../../Assets/ICONE/Home.svg";
import AboutUs from "../../Assets/ICONE/AboutUs.svg";
import ContactUs from "../../Assets/ICONE/ContactUs.svg";
import FAQ from "../../Assets/ICONE/FAQ.svg";
import Blogs from "../../Assets/ICONE/Blogs.svg";
import { SwipeableDrawer } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Tlogo from "../../Assets/Tlogo.png";
import CopyrightIcon from "@mui/icons-material/Copyright";
import webLogo from "../../Assets/web_logo.png";
// import signUP from "../../Assets/add-user.png";

const Navbar = () => {
  const [navbarSwitch, setnavbarSwitch] = useState(false);
  const history = useNavigate();
  const Tabsection = [
    { title: "Home", Icone: Home, route: "" },
    { title: "About Us", Icone: AboutUs, route: "aboutUs" },
    { title: "Contact Us", Icone: ContactUs, route: "contactUs" },
    { title: "FAQ", Icone: FAQ, route: "faq" },
    // { title: "Sign up", route: "OtpForm" },

    // { title: "Blogs", Icone: Blogs },
  ];
  const location = useLocation();
  return (
    <>
      <div className="web_hidden">
        <div className=" navbar_container flex  justify-between align-center px-3 py-3">
          <img alt="Logo" src={Strong_build} className="object-contain" />

          <MenuRoundedIcon
            className="mt-1 text-[#E98700]"
            onClick={() => setnavbarSwitch(true)}
          />
        </div>
        <SwipeableDrawer
          style={{ zIndex: "9999" }}
          anchor={"right"}
          open={navbarSwitch}
          onClose={() => setnavbarSwitch(false)}
          onOpen={() => setnavbarSwitch(true)}
        >
          <div className="  w-80 " onClick={() => setnavbarSwitch(false)}>
            <div className="Drawer_container h-full p-3">
              {" "}
              <div className="flex justify-between items-center">
                <img
                  alt="Logo"
                  src={Strong_build}
                  className="object-contain w-40px"
                  onClick={() => history("/")}
                />
                <div className="rounded_circle bg-[#E6E6E6] ">
                  <CloseRoundedIcon style={{ width: "20px" }} />
                </div>
              </div>
              <div className=" mt-16">
                {Tabsection.map((val, index) => (
                  <div
                    className="mt-3 bg-[#FAFAFA]  py-2 px-2 signUP"
                    onClick={() => {
                      history(`/${val.route}`);
                    }}
                    key={index}
                  >
                    <div className=" flex justify-start items-center px-2 ">
                      <img src={val.Icone} alt="..." className="me-3" />
                      <p className="w-100 text-center mb-0 font-[Lato] font-[500]">
                        {val.title}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <div className=" w-full mt-16 ">
                <img className=" mx-auto " src={Tlogo} alt="..." />
                <div className="w-full  my-3 flex justify-center  items-center bottom-1 absolute">
                  <CopyrightIcon
                    style={{ color: "#E98700" }}
                    className="me-1"
                  />
                  <p className="copyRight text-[#E98700] ">
                    2023 StrongBuild Pvt. Ltd. India
                  </p>
                </div>
              </div>
            </div>
          </div>
        </SwipeableDrawer>
      </div>

      <div className="sm_hidden px-10 py-3  border border-1">
        <nav className="flex justify-between items-center">
          <div>
            <img
              alt="Logo"
              src={webLogo}
              className="object-contain w-20"
              onClick={() => history("/")}
            />
          </div>
          <div>
            <ul className="flex items-center gap-8">
              {Tabsection.map((item) => {
                const isActive = `/${item.route}` === location.pathname;
                return (
                  <NavLink
                    key={item.route}
                    to={`/${item.route}`}
                    className={`text-base font-[Lato] cursor-pointer font-[500] signUp`}
                    activeClassName="visited"
                    style={{
                      color: isActive ? "#e98700" : "black",
                      fontWeight: "500",
                      fontSize: "1.2rem",
                    }}
                  >
                    {item.title}
                  </NavLink>
                );
              })}
            </ul>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
