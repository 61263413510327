import * as yup from "yup";

// Enter your contact details schema
export const validationSchema = yup.object({
  name: yup
    .string("Enter your name")
    .min(3, "Name must be at least 3 characters")
    .matches(/^[a-zA-Z\s]*$/, "Enter valid name")
    .required("Name is required"),

  phone: yup
    .string("Enter your phone")
    .matches(/^[0-9]*[^.]{10}$/, "Enter valid mobile number")
    .required("Mobile Number is required"),
});
