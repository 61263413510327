import React from "react";
import Strong_build from "../Assets/Strong_build.svg";

// import Link_IMG from "../Assets/Link_IMG.svg";
import fb from "../Assets/ICONE/faceBookLogo.svg";
import twitter from "../Assets/ICONE/twitterLogo.svg";
import insta from "../Assets/ICONE/instaLogo.svg";
import yt from "../Assets/ICONE/ytLogo.svg";
import { PostCountANDevent } from "./Reusable/AxiosInstance";

import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import CopyrightIcon from "@mui/icons-material/Copyright";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const history = useNavigate();
  return (
    <div className="bg-[#333333] pb-5 lg:px-20">
      <div className="bg-[#333333] p-5">
        <img src={Strong_build} alt=".." className="lg:w-40" />
      </div>

      <hr />
      <div className=" lg:flex lg:my-10 ">
        <div>
          <div className="p-5">
            <p className="Learn_More">Learn More</p>
          </div>
          <div className="px-5 mb-4">
            <p
              className="Learn_More_sub mb-1 lg:text-base"
              onClick={() => history("/aboutUs")}
            >
              About Us
            </p>
            {/* <p className="Learn_More_sub mb-1">Services</p>
        <p className="Learn_More_sub">Private Policy</p> */}
          </div>
        </div>
        <div className="lg:ml-20">
          <div className="p-5">
            <p className="Learn_More">Contact Us</p>
          </div>
          <div className="px-5 mb-4">
            <div className=" flex  items-center mb-2">
              <p className="Learn_More_sub">Call on:</p>
              <a
                href="tel:8296241477"
                target="_blank"
                rel="noopener noreferrer"
                title="8296241477"
                className="land_footer_number"
              >
                <p className="Learn_More_sub ms-7 underline underline-offset-4 ">
                  +91 8296241477
                </p>
              </a>
            </div>
            <div className=" flex space-x-4 items-center">
              <p className="Learn_More_sub">Email ID:</p>
              <a
                href="mailto:strong.build@gmail.com"
                title="strong.build@gmail.com"
              >
                <p className="Learn_More_sub">strong.build@gmail.com</p>
              </a>
            </div>
          </div>
          <div className="px-3 flex ">
            <a
              href="https://www.facebook.com/cendrolbuild/"
              target="_blank"
              onClick={() => {
                if (sessionStorage.getItem("CustomerID")) {
                  PostCountANDevent("Facebook");
                }
              }}
            >
              <img src={fb} alt="fb" className="mr-[12px] cursor-pointer" />
            </a>

            <a
              href="https://twitter.com/Cendrol_in"
              target="_blank"
              onClick={() => {
                if (sessionStorage.getItem("CustomerID")) {
                  PostCountANDevent("Twitter");
                }
              }}
            >
              <img
                src={twitter}
                alt="twitter"
                className="mr-[12px] cursor-pointer"
              />
            </a>
            <a href="https://www.instagram.com/cendrolbuild/" target="_blank">
              <img
                src={insta}
                alt="Instagram"
                className="mr-[12px] cursor-pointer"
                onClick={() => {
                  if (sessionStorage.getItem("CustomerID")) {
                    PostCountANDevent("Instagram");
                  }
                }}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCUpqY12z7-GlNroRG09nOSg"
              target="_blank"
              onClick={() => {
                if (sessionStorage.getItem("CustomerID")) {
                  PostCountANDevent("Youtube");
                }
              }}
            >
              <img
                src={yt}
                alt="youtube"
                className="mr-[12px] cursor-pointer"
              />
            </a>
          </div>
        </div>
      </div>
      {/* 
      <hr /> */}

      <div className="px-5 mt-2 flex items-center">
        {/* <p className="Learn_More_sub">Visit our blog</p>
        <PlayArrowOutlinedIcon className="text-[#FFFFFF]" /> */}
      </div>

      {/* subscribe to news letter  */}

      {/* <div className="px-5 my-5 ">
        <p className="Learn_More_sub">Subscribe to our newsletter</p>
        <div class="input-group  mt-3">
          <input
            type="email"
            className="w-full"
            id="Email"
            name="Email"
            placeholder="Email"
            autocomplete="off"
          />
          <input class="button--submit px-4" value="Subscribe" type="submit" />
        </div>
      </div> */}
      <hr />
      <div className="px-5 my-3 flex justify-center  items-center">
        <CopyrightIcon style={{ color: "#E98700" }} className="me-1" />
        <p className="copyRight text-[#E98700] ">
          2023 StrongBuild Pvt. Ltd. India
        </p>
      </div>
    </div>
  );
};

export default Footer;
