import { NavLink, useNavigate } from "react-router-dom";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import CardLogo from "../../Assets/CardLogo.svg";

const BlackCard = ({
  imgs,
  tagtitle,
  CloseBTN,
  tagtitleSub,
  paddingCustom,
  handleClose1,
  handleClose,
}) => {
  const history = useNavigate();

  return (
    <div
      className={`bg-[#434344] p-5  ${
        paddingCustom === false && "m-5"
      }  rounded-md`}
    >
      {CloseBTN && (
        <div className="rounded_circle bg-[#E6E6E6] float-right lg:bg-[#E6E6E6] lg:rounded-full lg:h-7 lg:w-7">
          <NavLink to="/">
            <CloseRoundedIcon
              style={{ width: "20px", color: "#717171" }}
              onClick={() => {
                history("/");
                handleClose1();
              }}
              className="rounded-icon-web"
            />
          </NavLink>
        </div>
      )}
      <div className=" flex  justify-center items-center space-x-3">
        <img src={imgs} />
        {tagtitle}
      </div>
      {tagtitleSub}
    </div>
  );
};

export default BlackCard;
