import React, { useState } from "react";
import Navbar from "./Navbar/Navbar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Footer from "./Footer";
import { FormContactDetailsForTrending } from "./Reusable/FormContactDetails";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import { BorderBottom } from "@mui/icons-material";

const FAQ = () => {
  const [Drawer, setDrawer] = useState(false);
  return (
    <div>
      <div className="bg-[#f3f2ef] lg:py-10">
        <p className="px-3 py-5 text-xl font-[Lato] lg:px-14 lg:text-4xl">
          Frequently asked <em className="text-[#E98700]">questions</em>
        </p>
        <div className="lg:flex lg:justify-between lg:px-14">
          <div className="lg:basis-5/12">
            <Accordion id="bg_Gray">
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  style={{ fontFamily: "Lato" }}
                  className="faq_number"
                >
                  01 / What is StrongBuild?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq_number">
                  Strongbuild is a full-service construction company that can
                  meet all of your construction-related requirements in a single
                  place. From designing to key handover, we bring together
                  solutions to your problem at one place. We provide you with
                  detailed planning, government approvals (if needed), project
                  administration, material coordination, and contracting
                  services are all included in the price.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              id="bg_Gray"
              className="font-[Lato]"
              style={{ fontFamily: "Lato" }}
            >
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  style={{ fontFamily: "Lato" }}
                  className="faq_number"
                >
                  02 / What is Design Generator?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq_number">
                  Design Generator is a tool where you can fill out the basic
                  details and get a customized design recommendation.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion id="bg_Gray">
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  style={{ fontFamily: "Lato" }}
                  className="faq_number"
                >
                  03 / Do we offer warranty?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq_number">
                  Yes, we do provide with 10 years of structural warranty and 1
                  year of service warranty.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion id="bg_Gray">
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  style={{ fontFamily: "Lato" }}
                  className="faq_number"
                >
                  04 / Can I track my home construction?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq_number">
                  Yes, you will be able to track the progress of your home from
                  the comfort of your home.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion id="bg_Gray">
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  style={{ fontFamily: "Lato" }}
                  className="faq_number"
                >
                  05 / Why is the StrongBuild method faster?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq_number">
                  Strongbuild method of construction uses preconstructed walls
                  which minimizes the time for construction as well as improves
                  the efficiency of construction.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion id="bg_Gray">
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  style={{ fontFamily: "Lato" }}
                  className="faq_number"
                >
                  06 / What is unique about strongbuild design and planning?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq_number">
                  We have more than 20 professionally experienced architects
                  with us, and their impressive portfolio of completed projects
                  speaks to their talent and expertise as designers. The project
                  management system of storngbuild helps its clients in smooth
                  progress of their project.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion id="bg_Gray">
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  style={{ fontFamily: "Lato" }}
                  className="faq_number"
                >
                  07 / What if I have my own design and architect, does your
                  package provide with only civil work services?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq_number">
                  Yes, there is no problem if you have your own designs, we will
                  give you a revised quote according to your requirements be it
                  just civil work.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="lg:basis-5/12">
            <Accordion id="bg_Gray">
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                style={{ borderTop: "1px solid #D1D1D1" }}
              >
                <Typography
                  style={{ fontFamily: "Lato" }}
                  className="faq_number"
                >
                  08 / What happens if the construction projects get delayed?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq_number">
                  Relax, we have provision for that too. If your projects get
                  delayed in any manner from our side, we have a compensation
                  clause which will provide the client with redemption in total
                  cost.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion id="bg_Gray">
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  style={{ fontFamily: "Lato" }}
                  className="faq_number"
                >
                  09 / Will my price ever change during construction?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq_number">
                  No. Only if you ask for a change order or if we need to use
                  the escalation clause (for instance, if lumber costs rise by
                  25% after you sign your contract) could your price change
                  during construction. Since this is unusual, you will be given
                  choices if it does.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion id="bg_Gray">
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  style={{ fontFamily: "Lato" }}
                  className="faq_number"
                >
                  10 / Is the estimate of cost calculator accurate?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq_number">
                  The cost estimator does indeed provide an exact estimate.
                  However, the final estimate can occasionally differ by about
                  10–12%.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion id="bg_Gray">
              <AccordionSummary
                expandIcon={<ExpandCircleDownOutlinedIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography
                  style={{
                    fontFamily: "Lato",
                    // paddingBottom: "30px",
                  }}
                  className="faq_number"
                >
                  11 / Are interiors included in the StrongBuild package?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="faq_number">
                  No, we don’t provide interior services as of now.
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>

      <FormContactDetailsForTrending
        Drawer={Drawer}
        setDrawer={setDrawer}
        cardClick={true}
      />
    </div>
  );
};

export default FAQ;
