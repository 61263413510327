import React, { useState } from "react";
import "./reusable.css";

import trend from "../../Assets/trending_one.png";
import trend2 from "../../Assets/trending_two.png";
import trend3 from "../../Assets/trending_three.png";
import trend4 from "../../Assets/trending_four.png";
import trend5 from "../../Assets/trending_five.png";
import trend6 from "../../Assets/trending_six.png";
import trend7 from "../../Assets/trending_seven.png";
import trend8 from "../../Assets/trending_eight.png";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import FormContactDetails, {
  FormContactDetailsForTrending,
} from "./FormContactDetails";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const TrendingDesignscard = () => {
  const [Drawer, setDrawer] = useState(false);
  return (
    <div className="TrendingDesignscard" style={{ position: "relative" }}>
      <div className="flag_position">
        <div className="flag1">1st</div>
      </div>
      <Card
        style={{
          boxShadow: "none",
          filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
        }}
        // onClick={() => setDrawer(true)}
      >
        <CardMedia
          sx={{ height: 180 }}
          image={trend}
          title="..."
          className=" relative"
        >
          <div class="ribbon">
            <span class="ribbon2 pt-4">1</span>
          </div>
          <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
            <Rating
              name="text-feedback"
              value={5}
              readOnly
              precision={0.5}
              color="warning"
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55, color: "#E98700" }}
                  fontSize="inherit"
                  color="warning"
                />
              }
            />
            <div className="heart_radius">
              <FavoriteBorderIcon
                color="warning"
                style={{ color: "#faaf00" }}
              />
            </div>
          </div>
        </CardMedia>

        <CardContent>
          <p className="Simp_Himp mb-3">Simp Himp</p>
          <div className=" flex justify-between  items-center mb-4">
            <p className="SqFt">1500 Sq.Ft.</p>
            <p className="SqFt">2 BHK</p>
          </div>
          <div className=" flex justify-between items-center mt-2 ">
            <div className=" space-y-2">
              <p className="Traditional ">Traditional </p>
              <p className="Traditional_sub">11 months</p>
            </div>
            <div className="h-6  Pipe "></div>
            <div className=" space-y-2">
              <p className="Traditional ">StrongBuild </p>
              <p className="Traditional_sub">4 months</p>
            </div>
          </div>
        </CardContent>
      </Card>
      <FormContactDetailsForTrending
        Drawer={Drawer}
        setDrawer={setDrawer}
        cardClick={true}
      />
    </div>
  );
};

export const TrendingDesignscard1 = () => {
  const [Drawer, setDrawer] = useState(false);
  return (
    <div className="TrendingDesignscard" style={{ position: "relative" }}>
      <div className="flag_position">
        <div className="flag1">1st</div>
      </div>
      <Card
        onClick={() => setDrawer(true)}
        style={{
          boxShadow: "none",
          filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
        }}
      >
        <CardMedia
          sx={{ height: 180 }}
          image={trend2}
          title="..."
          className=" relative bg_issue"
        >
          <div class="ribbon">
            <span class="ribbon2 pt-4">2</span>
          </div>
          <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
            <Rating
              name="text-feedback"
              value={5}
              readOnly
              precision={0.5}
              color="warning"
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55, color: "#E98700" }}
                  fontSize="inherit"
                  color="warning"
                />
              }
            />
            <div className="heart_radius">
              <FavoriteBorderIcon
                color="warning"
                style={{ color: "#faaf00" }}
              />
            </div>
          </div>
        </CardMedia>

        <CardContent>
          <p className="Simp_Himp mb-3">Contog House</p>
          <div className=" flex justify-between  items-center mb-4">
            <p className="SqFt">2500 Sq.Ft.</p>
            <p className="SqFt">3 BHK</p>
          </div>
          <div className=" flex justify-between items-center mt-2 ">
            <div className=" space-y-2">
              <p className="Traditional ">Traditional </p>
              <p className="Traditional_sub">14 months</p>
            </div>
            <div className="h-6  Pipe "></div>
            <div className=" space-y-2">
              <p className="Traditional ">StrongBuild </p>
              <p className="Traditional_sub">5 months</p>
            </div>
          </div>
        </CardContent>
      </Card>
      <FormContactDetailsForTrending
        Drawer={Drawer}
        setDrawer={setDrawer}
        cardClick={true}
      />
    </div>
  );
};

export const TrendingDesignscard2 = () => {
  const [Drawer, setDrawer] = useState(false);
  return (
    <div className="TrendingDesignscard" style={{ position: "relative" }}>
      <div className="flag_position">
        <div className="flag1">1st</div>
      </div>
      <Card
        onClick={() => setDrawer(true)}
        style={{
          boxShadow: "none",
          filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
        }}
      >
        <CardMedia
          sx={{ height: 180 }}
          image={trend3}
          title="..."
          className=" relative bg_issue"
        >
          <div class="ribbon">
            <span class="ribbon2 pt-4">3</span>
          </div>
          <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
            <Rating
              name="text-feedback"
              value={5}
              readOnly
              precision={0.5}
              color="warning"
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55, color: "#E98700" }}
                  fontSize="inherit"
                  color="warning"
                />
              }
            />
            <div className="heart_radius">
              {" "}
              <FavoriteBorderIcon
                color="warning"
                style={{ color: "#faaf00" }}
              />
            </div>
          </div>
        </CardMedia>

        <CardContent>
          <p className="Simp_Himp mb-3">C2 Hous</p>
          <div className=" flex justify-between  items-center mb-4">
            <p className="SqFt">3000 Sq.Ft.</p>
            <p className="SqFt">3 BHK</p>
          </div>
          <div className=" flex justify-between items-center mt-2 ">
            <div className=" space-y-2">
              <p className="Traditional ">Traditional </p>
              <p className="Traditional_sub">16 months</p>
            </div>
            <div className="h-6  Pipe "></div>
            <div className=" space-y-2">
              <p className="Traditional ">StrongBuild </p>
              <p className="Traditional_sub">6 months</p>
            </div>
          </div>
        </CardContent>
      </Card>
      <FormContactDetailsForTrending
        Drawer={Drawer}
        setDrawer={setDrawer}
        cardClick={true}
      />
    </div>
  );
};
export const TrendingDesignscard3 = () => {
  const [Drawer, setDrawer] = useState(false);
  return (
    <div className="TrendingDesignscard" style={{ position: "relative" }}>
      <div className="flag_position">
        <div className="flag1">1st</div>
      </div>
      <Card
        style={{
          boxShadow: "none",
          filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
        }}
        onClick={() => setDrawer(true)}
      >
        <CardMedia
          sx={{ height: 180 }}
          image={trend4}
          title="..."
          className=" relative bg_issue"
        >
          <div class="ribbon">
            <span class="ribbon2 pt-4">4</span>
          </div>
          <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
            <Rating
              name="text-feedback"
              value={4}
              readOnly
              precision={0.5}
              color="warning"
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55, color: "#FFFFFF" }}
                  fontSize="inherit"
                  color="primary"
                />
              }
            />
            <div className="heart_radius">
              {" "}
              <FavoriteBorderIcon
                color="warning"
                style={{ color: "#faaf00" }}
              />
            </div>
          </div>
        </CardMedia>

        <CardContent>
          <p className="Simp_Himp mb-3">The Hantal</p>
          <div className=" flex justify-between  items-center mb-4">
            <p className="SqFt">3200 Sq.Ft.</p>
            <p className="SqFt">3 BHK</p>
          </div>
          <div className=" flex justify-between items-center mt-2 ">
            <div className=" space-y-2">
              <p className="Traditional ">Traditional </p>
              <p className="Traditional_sub">12 months</p>
            </div>
            <div className="h-6  Pipe "></div>
            <div className=" space-y-2">
              <p className="Traditional ">StrongBuild </p>
              <p className="Traditional_sub">5 months</p>
            </div>
          </div>
        </CardContent>
      </Card>
      <FormContactDetailsForTrending
        Drawer={Drawer}
        setDrawer={setDrawer}
        cardClick={true}
      />
    </div>
  );
};

export const TrendingDesignscard4 = () => {
  const [Drawer, setDrawer] = useState(false);
  return (
    <div className="TrendingDesignscard" style={{ position: "relative" }}>
      <div className="flag_position">
        <div className="flag1">1st</div>
      </div>
      <Card
        style={{
          boxShadow: "none",
          filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
        }}
        onClick={() => setDrawer(true)}
      >
        <CardMedia
          sx={{ height: 180 }}
          image={trend5}
          title="..."
          className=" relative bg_issue"
        >
          <div class="ribbon">
            <span class="ribbon2 pt-4">5</span>
          </div>
          <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
            <Rating
              name="text-feedback"
              value={4}
              readOnly
              precision={0.5}
              color="warning"
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55, color: "#FFFFFF" }}
                  fontSize="inherit"
                  color="white"
                />
              }
            />
            <div className="">
              {" "}
              <FormContactDetailsForTrending
                color="warning"
                style={{ color: "#faaf00" }}
              />
            </div>
            <div className="heart_radius">
              {" "}
              <FavoriteBorderIcon
                color="warning"
                style={{ color: "#faaf00" }}
              />
            </div>
          </div>
        </CardMedia>

        <CardContent>
          <p className="Simp_Himp mb-3">Smrti Vani</p>
          <div className=" flex justify-between  items-center mb-4">
            <p className="SqFt">2000 Sq.Ft.</p>
            <p className="SqFt">2 BHK</p>
          </div>
          <div className=" flex justify-between items-center mt-2 ">
            <div className=" space-y-2">
              <p className="Traditional ">Traditional </p>
              <p className="Traditional_sub">11 months</p>
            </div>
            <div className="h-6  Pipe "></div>
            <div className=" space-y-2">
              <p className="Traditional ">StrongBuild </p>
              <p className="Traditional_sub">4 months</p>
            </div>
          </div>
        </CardContent>
      </Card>
      <FormContactDetailsForTrending
        Drawer={Drawer}
        setDrawer={setDrawer}
        cardClick={true}
      />
    </div>
  );
};

export const TrendingDesignscard5 = () => {
  const [Drawer, setDrawer] = useState(false);
  return (
    <div className="TrendingDesignscard" style={{ position: "relative" }}>
      <div className="flag_position">
        <div className="flag1">1st</div>
      </div>
      <Card
        style={{
          boxShadow: "none",
          filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
        }}
        onClick={() => setDrawer(true)}
      >
        <CardMedia
          sx={{ height: 180 }}
          image={trend6}
          title="..."
          className=" relative bg_issue"
        >
          <div class="ribbon">
            <span class="ribbon2 pt-4">6</span>
          </div>
          <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
            <Rating
              name="text-feedback"
              value={4}
              readOnly
              precision={0.5}
              color="warning"
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55, color: "#FFFFFF" }}
                  fontSize="inherit"
                  color="warning"
                />
              }
            />
            <div className="heart_radius">
              {" "}
              <FavoriteBorderIcon
                color="warning"
                style={{ color: "#faaf00" }}
              />
            </div>
          </div>
        </CardMedia>

        <CardContent>
          <p className="Simp_Himp mb-3">Screen House</p>
          <div className=" flex justify-between  items-center mb-4">
            <p className="SqFt">3200 Sq.Ft.</p>
            <p className="SqFt">4 BHK</p>
          </div>
          <div className=" flex justify-between items-center mt-2 ">
            <div className=" space-y-2">
              <p className="Traditional ">Traditional </p>
              <p className="Traditional_sub">12 months</p>
            </div>
            <div className="h-6  Pipe "></div>
            <div className=" space-y-2">
              <p className="Traditional ">StrongBuild </p>
              <p className="Traditional_sub">5 months</p>
            </div>
          </div>
        </CardContent>
      </Card>
      <FormContactDetailsForTrending
        Drawer={Drawer}
        setDrawer={setDrawer}
        cardClick={true}
      />
    </div>
  );
};
export const TrendingDesignscard6 = () => {
  const [Drawer, setDrawer] = useState(false);
  return (
    <div className="TrendingDesignscard" style={{ position: "relative" }}>
      <div className="flag_position">
        <div className="flag1">1st</div>
      </div>
      <Card
        style={{
          boxShadow: "none",
          filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
        }}
        onClick={() => setDrawer(true)}
      >
        <CardMedia
          sx={{ height: 180 }}
          image={trend7}
          title="..."
          className=" relative bg_issue"
        >
          <div class="ribbon">
            <span class="ribbon2 pt-4">7</span>
          </div>
          <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
            <Rating
              name="text-feedback"
              value={4}
              readOnly
              precision={0.5}
              color="warning"
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55, color: "#FFFFFF" }}
                  fontSize="inherit"
                  color="warning"
                />
              }
            />
            <div className="">
              {" "}
              <FormContactDetailsForTrending
                color="warning"
                style={{ color: "#faaf00" }}
              />
            </div>
            <div className="heart_radius">
              {" "}
              <FavoriteBorderIcon
                color="warning"
                style={{ color: "#faaf00" }}
              />
            </div>
          </div>
        </CardMedia>

        <CardContent>
          <p className="Simp_Himp mb-3">Boxis G1</p>
          <div className=" flex justify-between  items-center mb-4">
            <p className="SqFt">3500 Sq.Ft.</p>
            <p className="SqFt">2 BHK</p>
          </div>
          <div className=" flex justify-between items-center mt-2 ">
            <div className=" space-y-2">
              <p className="Traditional ">Traditional </p>
              <p className="Traditional_sub">14 months</p>
            </div>
            <div className="h-6  Pipe "></div>
            <div className=" space-y-2">
              <p className="Traditional ">StrongBuild </p>
              <p className="Traditional_sub">6 months</p>
            </div>
          </div>
        </CardContent>
      </Card>
      <FormContactDetailsForTrending
        Drawer={Drawer}
        setDrawer={setDrawer}
        cardClick={true}
      />
    </div>
  );
};
export const TrendingDesignscard7 = () => {
  const [Drawer, setDrawer] = useState(false);
  return (
    <div className="TrendingDesignscard pb-4" style={{ position: "relative" }}>
      <div className="flag_position">
        <div className="flag1">1st</div>
      </div>
      <Card
        style={{
          boxShadow: "none",
          filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
        }}
        onClick={() => setDrawer(true)}
      >
        <CardMedia
          sx={{ height: 180 }}
          image={trend8}
          title="..."
          className=" relative bg_issue"
        >
          <div class="ribbon">
            <span class="ribbon2 pt-4">8</span>
          </div>
          <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
            <Rating
              name="text-feedback"
              value={3}
              readOnly
              precision={0.5}
              color="warning"
              emptyIcon={
                <StarIcon
                  style={{ opacity: 0.55, color: "#FFFFFF" }}
                  fontSize="inherit"
                  color="warning"
                />
              }
            />
            <div className="heart_radius">
              {" "}
              <FavoriteBorderIcon
                color="warning"
                style={{ color: "#faaf00" }}
              />
            </div>
          </div>
        </CardMedia>

        <CardContent>
          <p className="Simp_Himp mb-3">Hamachi House</p>
          <div className=" flex justify-between  items-center mb-4">
            <p className="SqFt">2500 Sq.Ft.</p>
            <p className="SqFt">3 BHK</p>
          </div>
          <div className=" flex justify-between items-center mt-2 ">
            <div className=" space-y-2">
              <p className="Traditional ">Traditional </p>
              <p className="Traditional_sub">10 months</p>
            </div>
            <div className="h-6  Pipe "></div>
            <div className=" space-y-2">
              <p className="Traditional ">StrongBuild </p>
              <p className="Traditional_sub">4 months</p>
            </div>
          </div>
        </CardContent>
      </Card>
      <FormContactDetailsForTrending
        Drawer={Drawer}
        setDrawer={setDrawer}
        cardClick={true}
      />
    </div>
  );
};

export default TrendingDesignscard;
