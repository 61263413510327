import React from "react";
import Carousel from "react-material-ui-carousel";
// import Carausel1 from "../Assets/Carausel1.png";
// import Carausel2 from "../Assets/Carausel2.png";
// import Carausel3 from "../Assets/Carausel3.png";
import Carausel1 from "../Assets/home_one.png";
import Carausel2 from "../Assets/home_two.png";
import Carausel3 from "../Assets/home_three.png";

function Item({ item }) {
  return (
    <img src={item.img} alt="..." style={{ width: "100%", height: "100%" }} />
  );
}

const CarouselHome = () => {
  var items = [
    {
      img: Carausel1,
    },
    {
      img: Carausel2,
    },
    {
      img: Carausel3,
    },
  ];
  return (
    <div className="  mt-4 h-60">
      <Carousel
        // index={index}
        // onChange={handleChange}
        interval={4000}
        indicators={false}
        stopAutoPlayOnHover
        swipe
        animation="fade"
        className="my-carousel"
      >
        {items.map((item, i) => (
          <Item key={i} item={item} />
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselHome;
