import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import methodIcone from "../Assets/methodIcone.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import QuizCard from "./Reusable/QuizCard";
import { AxiosInstance } from "./Reusable/AxiosInstance";
import FormContactDetails, {
  ThankYouForm,
} from "./Reusable/FormContactDetails";
import { ThankYOUCard, ThankYouQuiz } from "./MethodStrongBuild";
import { Modal } from "@mui/material";
import headsUp from "../Assets/Process.png";
// import { BlackCard } from "./ContactUs";
import CardLogo from "../Assets/CardLogo.svg";
import BlackCard from "./Reusable/BlackCard";

const Quiz = () => {
  const [myRadio, setMyRadio] = useState("");
  const [Drawer, setDrawer] = useState(false);
  const [page, setpage] = useState(1);
  const [Option, setOption] = useState([]);
  const [title, settitle] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [objectID, setobjectID] = useState("");
  const history = useNavigate();
  let checkedIds = [];
  console.log(myRadio, "radio value");
  function handleCheckboxChange(event) {
    let checkboxId = event.target.value;
    let isChecked = event.target.checked;

    if (isChecked) {
      setMyRadio("");
      // Add the ID of the checked checkbox to the array
      checkedIds.push(checkboxId);
    } else {
      // Remove the ID of the unchecked checkbox from the array
      checkedIds = checkedIds.filter((id) => id !== checkboxId);
    }
  }
  function handleCheckboxChangeRadio(event) {
    setMyRadio(event.target.value);
  }

  const nextApi = (pageNumber) => {
    AxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/get-question?question_no=${pageNumber}`
    ).then((response) => {
      // console.log("/get-question?question_no=", response.data.result[0]);

      setOption(response.data.result[0].options);
      settitle(response.data.result[0].question);
      setobjectID(response.data.result[0]._id);
    });
  };
  const backApi = (pageNumber) => {
    AxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/get-question?question_no=${pageNumber}`
    ).then((responsedata) => {
      // console.log(responsedata.data.result[0]);
      AxiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/customer-quiz-response`,
        {
          custome_id: sessionStorage.getItem("CustomerID"),
          question_id: responsedata.data.result[0]._id,
        }
      ).then((response) => {
        response?.data?.result[0]?.question?.custome_answer?.map((val, i) => {
          checkedIds.push(val);
        });
        setOption(response.data.result[0].question.options);
        settitle(response.data.result[0].question.question);
        setobjectID(response.data.result[0].question._id);
      });
    });
  };

  const postMyQuiz = () => {
    AxiosInstance.post(`${process.env.REACT_APP_BASE_URL}/quiz-response`, {
      // custome_answer: {},
      custome_answer: myRadio ? [myRadio] : [...new Set(checkedIds)],
      custome_id: sessionStorage.getItem("CustomerID"),
      question_id: objectID,
    })
      .then((res) => {
        setpage(page + 1);
        nextApi(page + 1);
        setMyRadio("");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    AxiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/get-question?question_no=${page}`
    ).then((response) => {
      // console.log("/get-question?question_no=", response.data.result[0]);

      setOption(response.data.result[0].options);
      settitle(response.data.result[0].question);
      setobjectID(response.data.result[0]._id);
    });
  }, []);
  const CounterComponent = useCallback(
    ({
      page,
      Option,
      title,
      handleCheckboxChange,
      setpage,
      objectID,
      checkedIds,
      nextApi,
      backApi,
      setDrawer,
      postMyQuiz,
    }) => {
      return (
        <QuizCard
          page={page}
          setpage={setpage}
          objectID={objectID}
          Option={Option}
          checkedIds={checkedIds}
          title={title}
          nextApi={nextApi}
          backApi={backApi}
          // setDrawer={setDrawer}
          handleCheckboxChange={handleCheckboxChange}
          handleCheckboxChangeRadio={handleCheckboxChangeRadio}
          handleOpen={handleOpen}
          postMyQuiz={postMyQuiz}
        />
      );
    },
    [Option, title]
  );

  return (
    <div className="lg:bg-[#F3F2EF] lg:py-20">
      <div className="widthTakeOurQuiz lg:border">
        {/* <FormContactDetails
        Drawer={Drawer}
        setDrawer={setDrawer}
        cardClick={false}
      /> */}
        {/* <ThankYouForm Drawer={Drawer} setDrawer={setDrawer} cardClick={false} /> */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div
            className="containerDesignw "
            style={{ background: "rgba(34, 30, 31, 0.9)" }}
          >
            <ThankYouQuiz handleClose={handleClose} />
          </div>
        </Modal>

        {/* <div className=" flex justify-end items-center p-4">
        <div className=" rounded-full  h-fit  bg-[#E6E6E6] ">
          <CloseRoundedIcon
            style={{ width: "20px" }}
            onClick={() => history("/")}
          />
        </div>
      </div> */}
        {/* <div className=" w-100 flex justify-center mt-3">
        <img src={methodIcone} alt=".." />
      </div>
      <p className="latest_cutting mt-2 px-2">
        Take our <span style={{ color: "#E98700" }}>quiz</span> to find out
        about your <span style={{ color: "#E98700" }}>ideal home</span>
      </p>
      <p className="Thequiz">
        The quiz will give you clarity about the kind of construction suits your
        needs.
      </p> */}
        <div className=" lg:w-[38rem] lg:m-auto lg:mb-5 ">
          <BlackCard
            CloseBTN={false}
            imgs={CardLogo}
            tagtitle={
              <p className=" mt-5 font-bold text-white text-xl">
                Take our <span className="text-[#E98700]"> quiz</span> to <br />{" "}
                find out about <br /> your
                <span className="text-[#E98700]"> ideal home</span>
              </p>
            }
            tagtitleSub={
              <p className="tagtitleSubblack px-5 mt-3 text-sm">
                The quiz will give you clarity about the kind of construction
                suits your needs.
              </p>
            }
          />
        </div>
        <div className=" lg:text-center lg:bg-[#F3F2EF] ">
          <CounterComponent
            page={page}
            setpage={setpage}
            objectID={objectID}
            Option={Option}
            title={title}
            checkedIds={checkedIds}
            nextApi={nextApi}
            backApi={backApi}
            postMyQuiz={postMyQuiz}
            // setDrawer={setDrawer}
            handleCheckboxChange={handleCheckboxChange}
            handleOpen={handleOpen}
            className="lg:w-96 "
          />
        </div>
        <div className="bg-[#F3F2EF] lg:text-center">
          <button
            className="Next w-1/2 py-4 lg:my-10 lg:w-52"
            // disabled={checkedIds.length === 0}
            onClick={() => {
              if (checkedIds.length !== 0) {
                if (page === 7) {
                  // setDrawer(true);
                  handleOpen();
                  postMyQuiz();
                  // history("/");
                } else {
                  postMyQuiz();
                }
              } else if (myRadio.length !== 0) {
                postMyQuiz();
              } else {
                alert("please Select the answer");
              }
            }}
            // disabled={checkedIds.length <= 0 || false}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Quiz;
