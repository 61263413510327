import React from "react";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import trad1 from "../../Assets/tradtion_one.png";
import trad2 from "../../Assets/tradtion_two.png";
import trad3 from "../../Assets/tradtion_three.png";
import trad4 from "../../Assets/tradtion_four.png";
import trad5 from "../../Assets/tradtion_five.png";
import trad6 from "../../Assets/tradtion_six.png";

// strong

import strong1 from "../../Assets/strong_one.png";
import strong2 from "../../Assets/strong_two.png";
import strong3 from "../../Assets/strong_three.png";
import strong4 from "../../Assets/strong_four.png";
import strong5 from "../../Assets/strong_five.png";
import strong6 from "../../Assets/strong_six.png";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

const StrongBuildHomeCard = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
        marginBottom: "30px",
      }}
      className="mb-1"
    >
      <CardMedia
        className="relative"
        sx={{ height: 250 }}
        image={trad1}
        title="..."
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={5}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="">
            <FavoriteBorderIcon
              color="warning"
              style={{
                color: "#faaf00",
                marginLeft: "47px",
                marginRight: "-4px",
                marginTop: "-402px",
                background: "#F3F2EFBF",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
          </div>
        </div>
      </CardMedia>
      <CardContent>
        <p className="Simp_Himp mb-1">City Haven</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">2000 SQ.FT.</p>
          <p className="SqFt">2 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -
            <span className="Traditional_sub">11 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export const StrongBuildHomeCard1 = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
      }}
    >
      <CardMedia
        className="relative"
        sx={{ height: 250 }}
        image={trad2}
        title="..."
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={5}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="">
            <FavoriteBorderIcon
              color="warning"
              style={{
                color: "#faaf00",
                marginLeft: "47px",
                marginRight: "-4px",
                marginTop: "-402px",
                background: "#F3F2EFBF",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
          </div>
        </div>
      </CardMedia>
      <CardContent>
        <p className="Simp_Himp mb-1">Urban Comfort</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">3000 SQ.FT.</p>
          <p className="SqFt">3 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -{" "}
            <span className="Traditional_sub">13 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
export const StrongBuildHomeCard2 = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
      }}
    >
      <CardMedia
        className="relative"
        sx={{ height: 250 }}
        image={trad3}
        title="..."
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={4}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="">
            <FavoriteBorderIcon
              color="warning"
              style={{
                color: "#faaf00",
                marginLeft: "47px",
                marginRight: "-4px",
                marginTop: "-402px",
                background: "#F3F2EFBF",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
          </div>
        </div>
      </CardMedia>
      <CardContent>
        <p className="Simp_Himp mb-1">My Solace</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">2000 Sq.Ft.</p>
          <p className="SqFt">2 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -{" "}
            <span className="Traditional_sub">11 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
export const StrongBuildHomeCard3 = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
      }}
    >
      <CardMedia
        className="relative"
        sx={{ height: 250 }}
        image={trad4}
        title="..."
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={4}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="">
            <FavoriteBorderIcon
              color="warning"
              style={{
                color: "#faaf00",
                marginLeft: "47px",
                marginRight: "-4px",
                marginTop: "-402px",
                background: "#F3F2EFBF",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
          </div>
        </div>
      </CardMedia>
      <CardContent>
        <p className="Simp_Himp mb-1">City Haven</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">2000 Sq.Ft.</p>
          <p className="SqFt">2 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -{" "}
            <span className="Traditional_sub">11 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
export const StrongBuildHomeCard4 = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
      }}
    >
      <CardMedia
        className="relative"
        sx={{ height: 250 }}
        image={trad5}
        title="..."
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={4}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="">
            <FavoriteBorderIcon
              color="warning"
              style={{
                color: "#faaf00",
                marginLeft: "47px",
                marginRight: "-4px",
                marginTop: "-402px",
                background: "#F3F2EFBF",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
          </div>
        </div>
      </CardMedia>
      <CardContent>
        <p className="Simp_Himp mb-1">Urban Comfort</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">2500 Sq.Ft.</p>
          <p className="SqFt">3 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -{" "}
            <span className="Traditional_sub">13 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

export const StrongBuildHomeCard5 = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
      }}
    >
      <CardMedia
        className="relative"
        sx={{ height: 250 }}
        image={trad6}
        title="..."
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={5}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="">
            <FavoriteBorderIcon
              color="warning"
              style={{
                color: "#faaf00",
                marginLeft: "47px",
                marginRight: "-4px",
                marginTop: "-402px",
                background: "#F3F2EFBF",
                padding: "2px",
                borderRadius: "4px",
              }}
            />
          </div>
        </div>
      </CardMedia>
      <CardContent>
        <p className="Simp_Himp mb-1">My Solace</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">2500 Sq.Ft.</p>
          <p className="SqFt">3 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -{" "}
            <span className="Traditional_sub">14 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};

// strong build cards here
export const StrongBuildCard1 = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
      }}
      className="mb-4"
    >
      <CardMedia
        className="relative"
        sx={{ height: 250 }}
        image={strong1}
        title="..."
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={5}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="flex items-center">
            <div>
              <FavoriteBorderIcon
                color="warning"
                style={{
                  color: "#faaf00",
                  marginLeft: "47px",
                  marginRight: "-75px",
                  marginTop: "-393px",
                  background: "#F3F2EFBF",
                  padding: "2px",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div className="rounded-md">
              <p className="py-1 px-2 bg-[#F3F2EFBF] text-[#E98700] rounded-sm font-bold text-sm font-[Play]">
                PRE-CAST
              </p>
            </div>
          </div>
        </div>
      </CardMedia>

      <CardContent>
        <p className="Simp_Himp mb-1">Urban Dweller</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">2000 SQ.FT.</p>
          <p className="SqFt">2 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -{" "}
            <span className="Traditional_sub">4 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
export const StrongBuildCard2 = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
      }}
    >
      <CardMedia
        sx={{ height: 250 }}
        image={strong2}
        title="..."
        className="relative"
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={4}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="flex items-center">
            <div>
              <FavoriteBorderIcon
                color="warning"
                style={{
                  color: "#faaf00",
                  marginLeft: "47px",
                  marginRight: "-75px",
                  marginTop: "-393px",
                  background: "#F3F2EFBF",
                  padding: "2px",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div className="rounded-md">
              <p className="py-1 px-2 bg-[#F3F2EFBF] text-[#E98700] rounded-sm font-bold text-sm font-[Play]">
                PRE-CAST
              </p>
            </div>
          </div>
        </div>
      </CardMedia>
      <CardContent>
        <p className="Simp_Himp mb-1">Modern Delight</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">3000 SQ.FT.</p>
          <p className="SqFt">3 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -{" "}
            <span className="Traditional_sub">5 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
export const StrongBuildCard3 = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
      }}
    >
      <CardMedia
        className="relative"
        sx={{ height: 250 }}
        image={strong3}
        title="..."
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={4}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="flex items-center">
            <div>
              <FavoriteBorderIcon
                color="warning"
                style={{
                  color: "#faaf00",
                  marginLeft: "47px",
                  marginRight: "-75px",
                  marginTop: "-393px",
                  background: "#F3F2EFBF",
                  padding: "2px",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div className="rounded-md">
              <p className="py-1 px-2 bg-[#F3F2EFBF] text-[#E98700] rounded-sm font-bold text-sm font-[Play]">
                PRE-CAST
              </p>
            </div>
          </div>
        </div>
      </CardMedia>
      <CardContent>
        <p className="Simp_Himp mb-1">Swayam Haz</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">3500 SQ.FT.</p>
          <p className="SqFt">3 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -{" "}
            <span className="Traditional_sub">6 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
export const StrongBuildCard4 = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
      }}
    >
      <CardMedia
        className="relative"
        sx={{ height: 250 }}
        image={strong4}
        title="..."
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={5}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="flex items-center">
            <div>
              <FavoriteBorderIcon
                color="warning"
                style={{
                  color: "#faaf00",
                  marginLeft: "47px",
                  marginRight: "-75px",
                  marginTop: "-393px",
                  background: "#F3F2EFBF",
                  padding: "2px",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div className="rounded-md">
              <p className="py-1 px-2 bg-[#F3F2EFBF] text-[#E98700] rounded-sm font-bold text-sm font-[Play]">
                PRE-CAST
              </p>
            </div>
          </div>
        </div>
      </CardMedia>
      <CardContent>
        <p className="Simp_Himp mb-1">Swet Dweller</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">2500 SQ.FT.</p>
          <p className="SqFt">2 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -{" "}
            <span className="Traditional_sub">5 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
export const StrongBuildCard5 = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
      }}
    >
      <CardMedia
        className="relative"
        sx={{ height: 250 }}
        image={strong5}
        title="..."
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={4}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="flex items-center">
            <div>
              <FavoriteBorderIcon
                color="warning"
                style={{
                  color: "#faaf00",
                  marginLeft: "47px",
                  marginRight: "-75px",
                  marginTop: "-393px",
                  background: "#F3F2EFBF",
                  padding: "2px",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div className="rounded-md">
              <p className="py-1 px-2 bg-[#F3F2EFBF] text-[#E98700] rounded-sm font-bold text-sm font-[Play]">
                PRE-CAST
              </p>
            </div>
          </div>
        </div>
      </CardMedia>
      <CardContent>
        <p className="Simp_Himp mb-1">Screen Home</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">4000 SQ.FT.</p>
          <p className="SqFt">4 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -{" "}
            <span className="Traditional_sub">6 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
export const StrongBuildCard6 = () => {
  return (
    <Card
      style={{
        boxShadow: "none",
        filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
      }}
    >
      <CardMedia
        className="relative"
        sx={{ height: 250 }}
        image={strong6}
        title="..."
      >
        <div className=" flex  justify-between items-center absolute  bottom-0 w-full p-4">
          <Rating
            name="text-feedback"
            value={4}
            readOnly
            precision={0.5}
            color="warning"
            emptyIcon={
              <StarIcon
                style={{ opacity: 0.55, color: "#FFFFFF" }}
                fontSize="inherit"
                color="warning"
              />
            }
          />
          <div className="flex items-center">
            <div>
              <FavoriteBorderIcon
                color="warning"
                style={{
                  color: "#faaf00",
                  marginLeft: "47px",
                  marginRight: "-75px",
                  marginTop: "-393px",
                  background: "#F3F2EFBF",
                  padding: "2px",
                  borderRadius: "4px",
                }}
              />
            </div>
            <div className="rounded-md">
              <p className="py-1 px-2 bg-[#F3F2EFBF] text-[#E98700] rounded-sm font-bold text-sm font-[Play]">
                PRE-CAST
              </p>
            </div>
          </div>
        </div>
      </CardMedia>
      <CardContent>
        <p className="Simp_Himp mb-1">G4 Haz</p>
        <div className=" flex justify-between  items-center mb-4">
          <p className="SqFt">4500 SQ.FT.</p>
          <p className="SqFt">5 BHK</p>
        </div>
        <div className="flex justify-start items-center">
          <p className="Traditional ">
            Project time line -{" "}
            <span className="Traditional_sub">6 Months</span>{" "}
          </p>
        </div>
      </CardContent>
    </Card>
  );
};
export default StrongBuildHomeCard;
