import { SwipeableDrawer, useScrollTrigger } from "@mui/material";
import React from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import congratulation from "../../Assets/congratulation.png";
import tickMark from "../../Assets/TickMark.png";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { validationSchema } from "../../schemas/validation";
import { AxiosInstance } from "./AxiosInstance";
import strongLogo from "../../Assets/strongLogo.svg";
import { useState } from "react";
import Spinner from "./Spinner";
import OTPInput from "react-otp-input";
import OtpForm from "./OtpForm";
const FormContactDetails = ({ Drawer, setDrawer, cardClick }) => {
  const history = useNavigate();
  const [spinner, SetSpinner] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      SetSpinner(true);
      AxiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/create-customer`,
        values
      )
        .then((response) => {
          SetSpinner(false);
          console.log(response.data);
          // sessionStorage.setItem("CustomerID", response.data?.result?._id);
          sessionStorage.setItem("submitted", true);
          resetForm("");
          // history("/");
          // setDrawer(true);
          history("/quiz");
        })
        .catch((error) => {
          console.error(error);
          alert(error.response.data.msg);
        });
      // alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <>
      <SwipeableDrawer
        style={{ zIndex: "9999" }}
        anchor={"bottom"}
        open={Drawer}
        onClose={() => setDrawer(false)}
        onOpen={() => setDrawer(true)}
      >
        <div>
          {/* <div className=" flex justify-end  items-end    px-5   mt-9    ">
            {" "}
            <CloseRoundedIcon
              onClick={() => (setDrawer(false), history("/"))}
              style={{ borderRadius: "50%", backgroundColor: "#E6E6E6" }}
            />
          </div> */}
          {/* <div className=" flex  justify-center  items-center">
            <img
              // src={congratulation}
              src={strongLogo}
              alt="..."
              className=" object-contain  h-15 w-15 "
            />
          </div> */}
          {/* <p className="Thankyou">Thank you for selecting your requirements.</p> */}
          {/* <p className="Thankyou">
            Sign-up with us to know more about the StrongBuild method of
            construction
          </p> */}
          {/* <p className="Thankyou_sub">
            Please leave your contact information and we will send you your
            <span style={{ color: "#E98700" }}> customized results </span> at
            the earliest .
          </p> */}
          <OtpForm />
        </div>
        {/* {cardClick && (
          <form onSubmit={formik.handleSubmit} className="Form_container py-10">
            <p className="Thankyou_sub">Enter your contact details</p>
            <div className="mx-6">
              <input
                className="inputField  border mt-3 px-2"
                name="name"
                placeholder="Enter Name..."
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.touched.name && formik.errors.name && (
                <p className=" text-red-400">{formik.errors.name}</p>
              )}
              <input
                className="inputField border mt-3  px-2"
                name="email"
                placeholder="Enter Email..."
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email && (
                <p className=" text-red-400">{formik.errors.email}</p>
              )}
              <input
                className="inputField border mt-3  px-2"
                name="phone"
                placeholder="Enter Your Mobile Number..."
                type="number"
                value={formik.values.phone}
                onKeyDown={(event) => {
                  let value = event.target.value;
                  if (value.length >= 10 && event.keyCode !== 8) {
                    event.preventDefault();
                  }
                }}
                onChange={formik.handleChange}
              />
              {formik.touched.phone && formik.errors.phone && (
                <p className=" text-red-400">{formik.errors.phone}</p>
              )}
              <button
                type="submit"
                className="saveContinue px-4 py-2 w-full mt-5 saveContinue text-white flex justify-center items-center flex-row-reverse"
                // onClick={() => {
                //   history("/quiz");
                // }}
              >
                <div className="text-white ml-4">Submit</div>
                {spinner && <Spinner />}
              </button>
            </div>
          </form>
        )} */}
      </SwipeableDrawer>
    </>
  );
};

export const FormContactDetailsForTrending = ({
  Drawer,
  setDrawer,
  cardClick,
}) => {
  const history = useNavigate();
  const [spinner, setSetSpinner] = useState(false);
  const submitted = sessionStorage.getItem("submitted");

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setSetSpinner(true);
      AxiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/create-customer`,
        values
      )
        .then((response) => {
          console.log(response.data);
          // sessionStorage.setItem("CustomerID", response.data?.result?._id);
          resetForm("");
          sessionStorage.setItem("submitted", true);
          setSetSpinner(false);
          setDrawer(false);
          history("/");
        })
        .catch((error) => {
          console.error(error);
          alert(error.response.data.msg);
        });
      // alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <>
      {/* {submitted ? (
        <SwipeableDrawer
          style={{ zIndex: "9999" }}
          anchor={"bottom"}
          open={Drawer}
          onClose={() => setDrawer(false)}
          onOpen={() => setDrawer(true)}
        >
          <div className="h-[30vh]">
            <div className=" flex justify-end  items-end    px-5   mt-9    ">
              {" "}
              <CloseRoundedIcon
                onClick={() => (setDrawer(false), history("/"))}
              />
            </div>
            <div className=" flex  justify-center  items-center bg-[] m-2">
              <img
                src={tickMark}
                alt="..."
                className=" object-contain w-16  "
              />
            </div>
            <p className="Thankyou">
              Thank you for selecting your requirements.
            </p>
            <p className="Thankyou p-2">
              Thank you for selecting your requirements.
            </p>
            <p className="Thankyou_sub">We’ll get back to you soon!</p>
          </div>
        </SwipeableDrawer>
      ) : (
        <SwipeableDrawer
          style={{ zIndex: "9999" }}
          anchor={"bottom"}
          open={Drawer}
          onClose={() => setDrawer(false)}
          onOpen={() => setDrawer(true)}
        >
          <div>
            <div className=" flex justify-end  items-end    px-5   mt-9    ">
              {" "}
              <CloseRoundedIcon
                onClick={() => (setDrawer(false), history("/"))}
              />
            </div>
            <div className=" flex  justify-center  items-center">
              <img
                src={strongLogo}
                alt="..."
                className=" object-contain    h-15 w-15"
              />
            </div>
            <p className="Thankyou">
              Thank you for selecting your requirements.
            </p>
            <p className="Thankyou">
              {" "}
              Want to know more about this
              <span style={{ color: "#E98700" }}> design</span> ?
            </p>
            <p className="Thankyou_sub">
              Please leave your contact information and we will contact you at
              the earliest.
            </p>
          </div>
          {cardClick && (
            <form
              onSubmit={formik.handleSubmit}
              className="Form_container py-10"
            >
              <p className="Thankyou_sub">Enter your contact details</p>

              <OtpForm />
            </form>
          )}
        </SwipeableDrawer>
      )} */}
    </>
  );
};

export const FormPageAbout = ({ Drawer, setDrawer, cardClick }) => {
  const history = useNavigate();
  const [spinner, setSetSpinner] = useState(false);
  const submitted = sessionStorage.getItem("submitted");

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setSetSpinner(true);
      AxiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/create-customer`,
        values
      )
        .then((response) => {
          console.log(response.data);
          sessionStorage.setItem("CustomerID", response.data?.result?._id);
          resetForm("");
          sessionStorage.setItem("submitted", true);
          setSetSpinner(false);
          setDrawer(false);
          // history("/");
        })
        .catch((error) => {
          console.error(error);
          alert(error.response.data.msg);
        });
      // alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <>
      {submitted ? (
        <SwipeableDrawer
          style={{ zIndex: "9999" }}
          anchor={"bottom"}
          open={Drawer}
          onClose={() => setDrawer(false)}
          onOpen={() => setDrawer(true)}
        >
          <div className="h-[30vh]">
            <div className=" flex justify-end  items-end    px-5   mt-9    ">
              {" "}
              <CloseRoundedIcon onClick={() => setDrawer(false)} />
            </div>
            <div className=" flex  justify-center  items-center">
              <img
                src={tickMark}
                alt="..."
                className=" object-contain w-16  "
              />
            </div>
            {/* <p className="Thankyou">Thank you for selecting your requirements.</p> */}
            <p className="Thankyou text-[#ffffff]">
              Thank you for selecting your requirements.
            </p>
            <p className="Thankyou_sub text-[#C0C0C0]">
              We’ll get back to you soon!
            </p>
          </div>
        </SwipeableDrawer>
      ) : (
        <SwipeableDrawer
          style={{ zIndex: "9999" }}
          anchor={"bottom"}
          open={Drawer}
          onClose={() => setDrawer(false)}
          onOpen={() => setDrawer(true)}
        >
          <div>
            {/* <div className=" flex justify-end  items-end    px-5   mt-9    ">
              {" "}
              <CloseRoundedIcon onClick={() => setDrawer(false)} />
            </div> */}
            {/* <div className=" flex  justify-center  items-center">
              <img
                src={strongLogo}
                alt="..."
                className=" object-contain    h-15 w-15"
              />
            </div> */}
            {/* <p className="Thankyou">Thank you for selecting your requirements.</p> */}
            {/* <p className="Thankyou">
              {" "}
              Want to know more about this
              <span style={{ color: "#E98700" }}> design</span> ?
            </p> */}
            {/* <p className="Thankyou_sub">
              Please leave your contact information and we will contact you at
              the earliest.
            </p> */}
          </div>
          {cardClick && (
            <form
              onSubmit={formik.handleSubmit}
              className="Form_container py-10"
            >
              {/* <p className="Thankyou_sub">Enter your contact details</p> */}
              <OtpForm />
            </form>
          )}
        </SwipeableDrawer>
      )}
    </>
  );
};

export const ThankYouForm = ({ Drawer, setDrawer }) => {
  // const history = useNavigate()
  const history = useNavigate();

  return (
    <>
      <SwipeableDrawer
        style={{ zIndex: "9999" }}
        anchor={"bottom"}
        open={Drawer}
        onClose={() => setDrawer(false)}
        onOpen={() => setDrawer(true)}
      >
        <div className="h-[40vh] bg-[#333333]">
          {/* <div className=" flex justify-end  items-end    px-5   mt-9    ">
            {" "}
            <CloseRoundedIcon
              className="bg-[#E6E6E6] rounded-full"
              onClick={() => (setDrawer(false), history("/"))}
            />
          </div> */}
          <div className=" flex  justify-center  items-center">
            {/* <ThankYouForm />
            <img
              src={congratulation}
              alt="..."
              className=" object-contain w-16  "
            /> */}
          </div>
          {/* <p className="Thankyou">Thank you for selecting your requirements.</p> */}
          {/* <p className="Thankyou">Thank you for selecting your requirements.</p>
          <p className="Thankyou_sub">We’ll get back to you soon!</p> */}
        </div>
      </SwipeableDrawer>
    </>
  );
};
export default FormContactDetails;
