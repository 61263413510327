import React, { useState } from "react";
import Navbar from "./Navbar/Navbar";
import "./home.css";
import CarouselHome from "./CarouselHome";
import TrendingDesignscard, {
  TrendingDesignscard1,
  TrendingDesignscard2,
  TrendingDesignscard3,
  TrendingDesignscard4,
  TrendingDesignscard5,
  TrendingDesignscard6,
  TrendingDesignscard7,
} from "./Reusable/TrendingDesignscard";
import Carousel from "react-grid-carousel";
import ArchitectsCard from "./Reusable/ArchitectsCard";
import yash from "../Assets/yash.svg";
import Doremon from "../Assets/Doremon.svg";
import Ui from "../Assets/UIUX.svg";
import ui4 from "../Assets/ui4.svg";
import ui5 from "../Assets/ui5.svg";
import ui6 from "../Assets/ui6.svg";
import ui7 from "../Assets/ui7.svg";
import homeBG from "../Assets/Group.png";

import design_poster from "../Assets/design_poster.png";
import Constructionmethods from "../Assets/Constructionmethods.png";
import StrongBuildHomeCard, {
  StrongBuildCard1,
  StrongBuildCard2,
  StrongBuildCard3,
  StrongBuildCard4,
  StrongBuildCard5,
  StrongBuildCard6,
  StrongBuildHomeCard1,
  StrongBuildHomeCard2,
  StrongBuildHomeCard3,
  StrongBuildHomeCard4,
  StrongBuildHomeCard5,
} from "./Reusable/StrongBuildHomeCard";
import ProfessionalmanagementCard, {
  ProfessionalmanagementCard1,
  ProfessionalmanagementCard2,
  ProfessionalmanagementCard3,
} from "./Reusable/ProfessionalmanagementCard";
import Quizposter from "../Assets/Quizposter.png";
import Frequently from "./Frequently";
import ComentSection, {
  ComentSection1,
  ComentSection2,
  ComentSection3,
} from "./Reusable/ComentSection";
import Footer from "./Footer";
import FormStartPlanning from "./FormStartPlanning";
import DesignForm from "./Reusable/DesignForm";
import { useNavigate } from "react-router-dom";
import FormContactDetails from "./Reusable/FormContactDetails";
import quizUpdate from "../Assets/Quizupdate.png";
import FAQ from "./FAQ";
import { ThankYOUCard } from "./MethodStrongBuild";
import Modal from "@mui/material/Modal";
import OtpForm from "./Reusable/OtpForm";
import HeadsUp from "../Assets/Process.png";
import Cookies from "js-cookie";
import customHome from "../Assets/Subtract.svg";
import palmTree from "../Assets/Subtract.png";
import { PostCountANDevent } from "./Reusable/AxiosInstance";
import cardTwo from "../Assets/Traditional homes/card2.png";
import cardOne from "../Assets/Traditional homes/card1.png";
import clock from "../Assets/Traditional homes/clock.png";
import dollar from "../Assets/Traditional homes/dollar.png";
import quality from "../Assets/Traditional homes/quality.png";

import useAnalytics from "../Component/CustomHook/useAnalytics";

const HomePage = () => {
  const submitted = sessionStorage.getItem("submitted");
  const { trackEvent } = useAnalytics();
  const history = useNavigate();
  const [Drawer, setDrawer] = useState(false);
  const [openDesign, setOpenDesign] = useState(false);
  const handleOpenDesign = () => setOpenDesign(true);
  const handleCloseDesign = () => setOpenDesign(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const isMobile = window.innerWidth <= 768; // Set b
  return (
    <div>
      <p
        onClick={() => {
          trackEvent("lTake Our Quiz Now!", {
            button_label: "Take Our Quiz Now!",
          });
          if (sessionStorage.getItem("CustomerID")) {
            history("/quiz");
          } else {
            history("/otpForm");
            Cookies.set("FromQuz", true, {
              expires: 1,
              path: "/",
            });
            Cookies.set("eventName", "QUIZ", {
              expires: 1,
              path: "/",
            });
          }
        }}
        className=" fixed bottom-6 QUIZ  bg-[#E98700] w-fit z-50 right-4 rounded-full p-5"
      >
        QUIZ !
      </p>

      <FormContactDetails
        Drawer={Drawer}
        setDrawer={setDrawer}
        cardClick={true}
      />
      <Navbar />
      <div className="w-full">
        <div>
          <div className="small_hidden">
            <Carousel
              cols={1}
              rows={1}
              loop
              hideArrow="false"
              className="sm:hidden"
            >
              <Carousel.Item>
                <img width="100%" src={homeBG} />
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="home_content">
            <p className="Title px-4 py-8 font-[Play]">
              Your go-to <span className="text-[#E98700]"> platform</span> to
              build
              <br />
              your <span className="text-[#E98700]">custom home</span>
            </p>
            <p className="sub_Title px-4 pb-3 text-center">
              Looking to build? Look no further.
            </p>
            <div className="flex justify-center items-center w-100">
              <FormStartPlanning cardClick={false} />
            </div>
          </div>
        </div>

        <div className="web_hidden">
          <CarouselHome />
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="w-full h-full flex items-center justify-end flex-col bg-[red]">
            {/* <ThankYOUCard handleClose={handleClose} /> */}
          </div>
        </Modal>
        <div className="mt-20">
          <div className="mt-20 flex items-center justify-between lg:mx-8 lg:mb-10">
            <p className="Trending_text">Trending ready to build designs</p>

            <p
              className="mr-4 font-[Lato] lg:text-2xl lg:mb-[10px] text-[10px] underline cursor-pointer"
              onClick={() => {
                trackEvent("Trending designs View All", {
                  button_label: "Trending designs View All",
                });
                if (sessionStorage.getItem("CustomerID")) {
                  handleOpen();
                  PostCountANDevent("Trending_Designs");
                } else {
                  history("/otpForm");
                  Cookies.set("eventName", "Trending Vew All", {
                    expires: 1,
                    path: "/",
                  });
                }
              }}
            >
              View all
            </p>
          </div>
          <div className="mt-4 trending_ready">
            <Carousel
              cols={4}
              rows={1}
              gap={20}
              // autoplay={2}
              loop
              responsiveLayout={[
                {
                  breakpoint: 990,
                  cols: 2,
                },
                {
                  breakpoint: 1024,
                  cols: 4,
                },
              ]}
            >
              <Carousel.Item key={1}>
                <TrendingDesignscard cardClick={true} />
              </Carousel.Item>
              <Carousel.Item key={2}>
                <TrendingDesignscard1 cardClick={true} />
              </Carousel.Item>
              <Carousel.Item key={3}>
                <TrendingDesignscard2 cardClick={true} />
              </Carousel.Item>
              <Carousel.Item key={4}>
                <TrendingDesignscard3 cardClick={true} />
              </Carousel.Item>
              <Carousel.Item key={5}>
                <TrendingDesignscard4 cardClick={true} />
              </Carousel.Item>
              <Carousel.Item key={6}>
                <TrendingDesignscard5 cardClick={true} />
              </Carousel.Item>
              <Carousel.Item key={7}>
                <TrendingDesignscard6 cardClick={true} />
              </Carousel.Item>
              <Carousel.Item key={8}>
                <TrendingDesignscard7 cardClick={true} />
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="flex justify-between items-center lg:mx-9 lg:mb-10 lg:my-10">
            <p className="Trending_text mt-1 mb-3 ">Expert Architects</p>
            <p
              className="mr-4 font-[Lato] lg:text-2xl lg:mb-[10px] text-[10px] underline  cursor-pointer"
              onClick={() => {
                trackEvent("Expert Architect View All", {
                  button_label: "Expert Architect View All",
                });
                if (sessionStorage.getItem("CustomerID")) {
                  handleOpen();
                  PostCountANDevent("Expert_Architect");
                } else {
                  history("/otpForm");
                  Cookies.set("eventName", "Expert Architect Vew All", {
                    expires: 1,
                    path: "/",
                  });
                }
              }}
            >
              View all
            </p>
          </div>

          <div className="mt-4 trending_ready ">
            <Carousel
              cols={isMobile ? 2 : 5}
              rows={1}
              gap={10}
              loop="true"
              responsiveLayout={[
                {
                  breakpoint: 990,
                  cols: 4,
                },
                {
                  breakpoint: 768,
                  cols: 3,
                  gap: 1,
                },
                {
                  breakpoint: 414,
                  cols: 3,
                  gap: 10,
                },
                {
                  breakpoint: 1024,
                  cols: 5,
                },
              ]}
            >
              <Carousel.Item key={1}>
                <ArchitectsCard
                  img={yash}
                  title="Yash Raj Pachori"
                  design="55 designs"
                  location="Chennai, India"
                />
              </Carousel.Item>
              <Carousel.Item key={2}>
                <ArchitectsCard
                  img={Doremon}
                  title="Sukriti Sharma "
                  design="108 designs"
                  location="Bangalore, India"
                />
              </Carousel.Item>
              <Carousel.Item key={3}>
                <ArchitectsCard
                  img={Ui}
                  title="Shivangi Jaiswal"
                  design="78 designs"
                  location="Mumbai, India"
                />
              </Carousel.Item>
              <Carousel.Item key={4}>
                <ArchitectsCard
                  img={ui4}
                  title="Aditya Bokade"
                  design="78 designs"
                  location="Mumbai, India"
                />
              </Carousel.Item>
              <Carousel.Item key={5}>
                <ArchitectsCard
                  img={ui5}
                  title="Sudha Ram"
                  design="65 designs"
                  location="Delhi, India"
                />
              </Carousel.Item>
              <Carousel.Item key={6}>
                <ArchitectsCard
                  img={ui6}
                  title="Soma Banerjee"
                  design="80 designs"
                  location="Bangalore, India"
                />
              </Carousel.Item>
              <Carousel.Item key={7}>
                <ArchitectsCard
                  img={ui7}
                  title="Rohit Jaiswal"
                  design="90 designs"
                  location="Mumbai, India"
                />
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="p-6  lg:flex lg:justify-between lg:py-16 lg:my-10">
            <div className="lg:ml-20">
              <p className="text-[28px] font-bold text-[#494847] font-[Play] lg:text-[60px]">
                Already have a <br />{" "}
                <span className="text-[#E98700]">design?</span>
              </p>
              <p className="my-2 text-[#717171] font-[Lato] text-[13px] lg:text-[24px]">
                Jump right into Building <br />
                <span className="text-[#E98700]">YOUR</span> dream house
              </p>
              <p className="font-semibold font-[Lato] text-[#494847] text-base mb-3 lg:text-[36px] we_are">
                We are here to meet <br /> all{" "}
                <span className="text-[#E98700]">your</span> needs.
              </p>
              <DesignForm
                openDesign={openDesign}
                handleOpenDesign={handleOpenDesign}
                handleCloseDesign={handleCloseDesign}
              />
            </div>

            <div
              className=" lg:ml-96 alreadyHaveaDesign"
              style={{ height: "8rem", marginLeft: "4rem" }}
            >
              <img
                src={customHome}
                alt="homeCustom"
                className="relative float-right -top-24 lg:static lg:float-none  lg:w-[26rem] imgProblem lg:mt-20"
              />
            </div>
          </div>

          {/* problem div---------  */}

          {/* -------problem div ---------- */}
          <div
            className="flex justify-between items-center strong_build_homes lg:mt-20"
            style={{
              width: "23rem",
            }}
          >
            <p className="Trending_text mt-2 mb-3">StrongBuild Homes</p>
            <p
              className="mr-4 font-[Lato] lg:text-2xl text-[10px] underline  cursor-pointer"
              onClick={() => {
                trackEvent("StrongBuild Homes Vew All", {
                  button_label: "StrongBuild Homes Vew All",
                });
                if (sessionStorage.getItem("CustomerID")) {
                  handleOpen();
                  PostCountANDevent("Strong_Build_Homes");
                } else {
                  history("/otpForm");
                  Cookies.set("eventName", "StrongBuild Homes Vew All", {
                    expires: 1,
                    path: "/",
                  });
                }
              }}
            >
              View all
            </p>
          </div>

          <div className="mt-4  trending_ready">
            <Carousel
              cols={isMobile ? 2 : 4}
              rows={1}
              gap={20}
              loop
              responsiveLayout={[
                {
                  breakpoint: 990,
                  cols: 3,
                },
                {
                  breakpoint: 768,
                  cols: 3,
                },
                {
                  breakpoint: 370,
                  cols: 3,
                },
                {
                  breakpoint: 1024,
                  cols: 4,
                },
              ]}
            >
              <Carousel.Item key={1}>
                <StrongBuildCard1 />
              </Carousel.Item>
              <Carousel.Item key={2}>
                <StrongBuildCard2 />
              </Carousel.Item>
              <Carousel.Item key={3}>
                <StrongBuildCard3 />
              </Carousel.Item>
              <Carousel.Item key={4}>
                <StrongBuildCard4 />
              </Carousel.Item>
              <Carousel.Item key={5}>
                <StrongBuildCard5 />
              </Carousel.Item>
              <Carousel.Item key={6}>
                <StrongBuildCard6 />
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="flex justify-between items-center strong_build_homes  lg:mt-10">
            <p className="Trending_text mt-2 mb-3 ">Traditional Homes</p>
            <p
              className="mr-4 font-[Lato] lg:text-2xl lg:mb-[10px] text-[10px] underline  cursor-pointer"
              onClick={() => {
                trackEvent("Traditional Homes Vew All", {
                  button_label: "Traditional Homes Vew All",
                });
                if (sessionStorage.getItem("CustomerID")) {
                  handleOpen();
                  PostCountANDevent("Traditional_Homes");
                } else {
                  history("/otpForm");
                  Cookies.set("eventName", "Traditional Homes Vew All", {
                    expires: 1,
                    path: "/",
                  });
                }
              }}
            >
              View all
            </p>
          </div>

          <div className="mt-4  trending_ready">
            <Carousel
              cols={isMobile ? 2 : 4}
              rows={1}
              gap={20}
              loop
              responsiveLayout={[
                {
                  breakpoint: 990,
                  cols: 3,
                },
                {
                  breakpoint: 768,
                  cols: 3,
                },
                {
                  breakpoint: 370,
                  cols: 3,
                },
                {
                  breakpoint: 1024,
                  cols: 4,
                },
              ]}
            >
              <Carousel.Item key={1}>
                <StrongBuildHomeCard />
              </Carousel.Item>
              <Carousel.Item key={2}>
                <StrongBuildHomeCard1 />
              </Carousel.Item>
              <Carousel.Item key={3}>
                <StrongBuildHomeCard2 />
              </Carousel.Item>
              <Carousel.Item key={4}>
                <StrongBuildHomeCard3 />
              </Carousel.Item>
              <Carousel.Item key={5}>
                <StrongBuildHomeCard4 />
              </Carousel.Item>
              <Carousel.Item key={6}>
                <StrongBuildHomeCard5 />
              </Carousel.Item>
            </Carousel>
          </div>
          <div
            className="mt-3 lg:hidden md:hidden"
            style={{
              backgroundImage: `url(${Constructionmethods})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
              height: "550px",
              width: "100%",
            }}
          >
            <div className="absolute">
              <button
                className="  px-4 py-2 Explore_StrongBuildBTN"
                onClick={() => {
                  history("/methods");
                  trackEvent("Explore StrongBuild Method", {
                    button_label: "Explore StrongBuild Method",
                    from: "web",
                  });
                }}
              >
                Explore StrongBuild Method
              </button>
            </div>
          </div>

          {/* tttttttttttt -----  */}
          <div className="lg:mt-5 lg:flex lg:justify-center lg:items-center lg:flex-col small_hidden">
            <div className="">
              <h2 className="lg:text-4xl lg:font-semibold lg:text-[#494847] lg:mt-6 lg:font-[Play]">
                Confused about different
                <span className="lg:text-[#E98700] lg:font-[Play]">
                  &nbsp; construction methods?
                </span>
              </h2>
              <p className="lg:text-[#494847] lg:text-xl lg:my-2 lg:mb-4 lg:text-center lg:font-[Lato]">
                We are here to help
                <span className="lg:text-[#E98700]"> YOU </span> understand
              </p>
              <div className=" lg:flex lg:justify-center lg:gap-5 ">
                <div className=" lg:border-red-300 lg:bg-[#494847] lg:px-5 lg:py-10 lg:text-white lg:rounded-md lg:shadow-[#E98700] lg:shadow-md">
                  <div className="lg:mb-5 lg:flex lg:justify-center lg:items-center">
                    <img src={cardTwo} alt="icon" className="lg:w-9" />
                  </div>
                  <p>option1</p>
                  <p className="lg:text-[#E98700] lg:font-semibold lg:uppercase">
                    Traditional
                  </p>
                  <div className="lg:flex lg:items-center  lg:mt-3">
                    <div>
                      <img src={clock} alt="clock" className="lg:w-6" />
                    </div>
                    <div className="lg:ml-5">12 Months +</div>
                  </div>
                  <div className="lg:flex lg:items-center  lg:mt-3">
                    <div>
                      <img src={dollar} alt="dollar image" className="lg:w-6" />
                    </div>
                    <div className="lg:ml-5">Variable price</div>
                  </div>
                  <div className="lg:flex lg:items-center   lg:mt-3">
                    <div>
                      <img
                        src={quality}
                        alt="dollar image"
                        className="lg:w-6"
                      />
                    </div>
                    <div className="lg:ml-5">Variable Quality </div>
                  </div>
                </div>
                {/* <div className="border lg:bg-[#494847] lg:px-5 lg:py-4 lg:text-white lg:rounded-md lg:shadow-[#E98700] lg:shadow-md lg:border-red-300">
                    <div className="text-center">
                      <img src={cardOne} alt="icon" className="lg:w-5 " />
                    </div>
                    <p>option2</p>
                    <p className="lg:text-[#E98700] lg:font-semibold lg:uppercase">
                      STRONGBUILD
                    </p>
                    <div className="lg:flex lg:items-center lg:justify-between lg:mt-3">
                      <div>
                        <img src={clock} alt="clock" className="lg:w-6" />
                      </div>
                      <div>9 months</div>
                    </div>
                    <div className="lg:flex lg:items-center lg:justify-between lg:mt-3">
                      <div>
                        <img
                          src={dollar}
                          alt="dollar image"
                          className="lg:w-6"
                        />
                      </div>
                      <div>fixed price</div>
                    </div>
                    <div className="lg:flex lg:items-center lg:justify-between lg:mt-3">
                      <div className="lg:text-center">
                        <img
                          src={quality}
                          alt="dollar image"
                          className="lg:w-6"
                        />
                      </div>
                      <div className="lg:ml-5 ">Superior Quality</div>
                    </div>
                  </div> */}
                <div className=" lg:border-red-300 lg:bg-[#494847] lg:px-5 lg:py-9 lg:text-white lg:rounded-md lg:shadow-[#E98700] lg:shadow-md">
                  <div className="lg lg:mb-5 lg:flex lg:justify-center lg:items-center">
                    <img src={cardOne} alt="icon" className="lg:w-10" />
                  </div>
                  <p>option2</p>
                  <p className="lg:text-[#E98700] lg:font-semibold lg:uppercase">
                    Strongbuild
                  </p>
                  <div className="lg:flex lg:items-center  lg:mt-3">
                    <div>
                      <img src={clock} alt="clock" className="lg:w-6" />
                    </div>
                    <div className="lg:ml-5">9 Months </div>
                  </div>
                  <div className="lg:flex lg:items-center  lg:mt-3">
                    <div>
                      <img src={dollar} alt="dollar image" className="lg:w-6" />
                    </div>
                    <div className="lg:ml-5">Fixed price</div>
                  </div>
                  <div className="lg:flex lg:items-center   lg:mt-3">
                    <div>
                      <img
                        src={quality}
                        alt="dollar image"
                        className="lg:w-6"
                      />
                    </div>
                    <div className="lg:ml-5">Superior Quality </div>
                  </div>
                </div>
              </div>
              {/* two card end -------------------  */}
            </div>
            <p className="lg:mt-7 lg:mb-3 lg:text-[#717171] font-[Lato]">
              Choose the method that is right for{" "}
              <span className="lg:text-[#E98700]">you!</span>{" "}
            </p>
            <button
              className="px-4 py-2 lg:bg-[#333333] lg:text-white lg:px-10 lg:rounded-md font-[Montserrat]"
              onClick={() => history("/methods")}
            >
              Explore StrongBuild Method
            </button>
          </div>
          {/* tttttttttttt------  */}
          <div className=" lg:bg-[#E6E6E6] lg:py-14 lg:my-10">
            <p className="Trending_text mt-2 mb-3 lg:text-center lg:fontbold proffesional_management">
              <span className="lg:text-[#E98700]">
                {" "}
                Professional management{" "}
              </span>
              of each project
            </p>
            <div className="mt-4  trending_ready">
              <Carousel
                cols={isMobile ? 2 : 4}
                rows={1}
                gap={10}
                loop
                hideArrow="false"
                responsiveLayout={[
                  {
                    breakpoint: 990,
                    cols: 3,
                  },
                  {
                    breakpoint: 768,
                    cols: 3,
                  },
                  {
                    breakpoint: 370,
                    cols: 3,
                  },
                ]}
              >
                <Carousel.Item key={1}>
                  <ProfessionalmanagementCard />
                </Carousel.Item>
                <Carousel.Item key={2}>
                  <ProfessionalmanagementCard1 />
                </Carousel.Item>
                <Carousel.Item key={3}>
                  <ProfessionalmanagementCard2 />
                </Carousel.Item>
                <Carousel.Item key={4}>
                  <ProfessionalmanagementCard3 />
                </Carousel.Item>
              </Carousel>
            </div>
          </div>

          {/* <p className="Trending_text mt-2 mb-3 ">
            Professional management of each project
          </p> */}
          <div className="mt-3 py-1 px-7 my-10 lg:my-20">
            <div className="mb-20">
              <div className="lg:mr-20">
                <p className="text-end text-[28px] font-bold font-[Play] lg:text-[52px]">
                  Find out what is your <br />{" "}
                  <span className="text-[#E98700]">ideal home</span>
                </p>
                <p className="text-end text-[#494847] my-3 font-[Lato] font-medium lg:text-[24px}">
                  Take our quiz and get a free <br /> design worth ₹50 thousand
                </p>
                <div className="text-end">
                  <button
                    className="px-4 py-2 bg-[#333] text-white rounded-md mt-4"
                    onClick={() => {
                      trackEvent("lTake Our Quiz Now!", {
                        button_label: "Take Our Quiz Now!",
                      });
                      if (sessionStorage.getItem("CustomerID")) {
                        history("/quiz");
                      } else {
                        history("/otpForm");
                        Cookies.set("eventName", "Quiz", {
                          expires: 1,
                          path: "/",
                        });
                      }
                    }}
                  >
                    Take Our Quiz Now!
                  </button>
                  <p className="text-[36px] lg:font-bold fifty_thousand lg:mt-2">
                    Take our quiz and get a{" "}
                    <span className="text-[#E98700]">free</span> <br />
                    <span className="text-[#E98700]">design</span> worth{" "}
                    <span className="text-[#E98700]">₹50 thousand</span>
                  </p>
                </div>
                <div className="absolute">
                  <img
                    src={palmTree}
                    alt="treehouse"
                    className="relative -left-6 w-48 -z-20 -top-20 lg:w-[28rem] lg:-top-96"
                  />
                </div>
              </div>
            </div>

            <div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ background: "rgba(34, 30, 31, 0.9)" }}
              >
                <div className="containerDesignw ">
                  <ThankYOUCard handleClose={handleClose} img={HeadsUp} />
                </div>
              </Modal>
            </div>
          </div>

          <FAQ />
          <div className="bg-[#C0C0C0] lg:m-0 lg:py-10">
            {/* comment section carousel over here */}
            <Carousel
              cols={1}
              rows={1}
              gap={10}
              loop
              responsiveLayout={[
                {
                  breakpoint: 990,
                  cols: 3,
                },
                {
                  breakpoint: 768,
                  cols: 3,
                },
                {
                  breakpoint: 370,
                  cols: 3,
                },
              ]}
            >
              <Carousel.Item key={1}>
                <ComentSection />
              </Carousel.Item>
              <Carousel.Item key={2}>
                <ComentSection1 />
              </Carousel.Item>
              <Carousel.Item key={3}>
                <ComentSection2 />
              </Carousel.Item>
              <Carousel.Item key={4}>
                <ComentSection3 />
              </Carousel.Item>
            </Carousel>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
