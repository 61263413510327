import React, { useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SwipeableDrawer,
} from "@mui/material";
import FormContactDetails, {
  ThankYouForm,
} from "./Reusable/FormContactDetails";
import { useFormik } from "formik";
import * as yup from "yup";
import { AxiosInstance } from "./Reusable/AxiosInstance";
import SearchLocation from "./Reusable/SearchLocation";
import { useNavigate } from "react-router-dom";
import Spinner from "./Reusable/Spinner";
import { ThankYOUCard } from "./MethodStrongBuild";
import Modal from "@mui/material/Modal";
import SuccessIcone from "../Assets/SuccessIcone.svg";
import Cookies from "js-cookie";
// import { BlackCard } from "./ContactUs";
import CardLogo from "../Assets/CardLogo.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextField from "@mui/material/TextField";
import PlacesAutocompleteDropdown from "./Reusable/PlacesAutocompleteDropdown";
import useAnalytics from "../Component/CustomHook/useAnalytics";
import closeBTnRefresh from "../Assets/Traditional homes/closeBtnRefresh.png";
import BlackCard from "./Reusable/BlackCard";

const FormStartPlanning = () => {
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const [FormSwitch, setFormSwitch] = useState(false);
  const [Drawer, setDrawer] = useState(false);
  const [query, setQuery] = useState("");
  const [cardClick, setCardClick] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  // const [rahul, setRahul] = useState(false);
  // const history = useNavigate();

  const validationSchema = yup.object({
    name: yup
      .string("Enter your name")
      .min(3, "Name must be at least 3 characters")
      .matches(/^[a-zA-Z\s]*$/, "Enter valid name")
      .required("Name is required"),
    plot_size: yup
      .string("Enter your plotsize")
      .required("Plot size is required"),
    end_price: yup
      .string("Enter your end price")
      .required("End price is required")
      .matches(/^0*[1-9]\d*$/, "select more than 0"),
    start_price: yup
      .string("Enter your start price")
      .required("Start price is required")
      .matches(/^0*[1-9]\d*$/, "select more than 0"),
    // location: yup
    //   .string("Enter your Location")
    //   .required("Location is required"),
    property_type: yup
      .string("Select property Type")
      .required("property Type is required"),
    construction_type: yup
      .string("Enter your Construction Type")
      .required("Construction Type is required"),
    number_of_floor: yup
      .string("Enter your Number of Floors")
      .required("Number of Floors is required"),
    number_of_badroom: yup
      .string("Enter your Number of Bedrooms")
      .required("Number of Bedrooms is required"),
    number_of_bathroom: yup
      .string("Enter your Number of Bathrooms")
      .required("Number of Bathrooms is required"),
    number_of_balconies: yup
      .string("Enter your Number of Balconies")
      .required("Number of Balconies is required"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    phone: yup
      .string("Enter your Mobile Number")
      .required("Mobile Number is required"),
    parking: yup.string("Enter your parking").required("Parking is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: JSON.parse(sessionStorage.getItem("Customer"))?.name || "",
      // location: "",
      property_type: "",
      construction_type: "",
      number_of_floor: "",
      number_of_badroom: "",
      number_of_bathroom: "",
      number_of_balconies: "",
      parking: "",
      email: "",
      phone: JSON.parse(sessionStorage.getItem("Customer"))?.phone || "",
      end_price: "",
      start_price: "",
      plot_size: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      console.log(values);
      setSpinner(true);
      const data = {
        name: JSON.parse(sessionStorage.getItem("Customer"))?.name || "",
        // location: "",
        property_type: values.property_type,
        construction_type: values.construction_type,
        number_of_floor: values.number_of_floor,
        number_of_badroom: values.number_of_badroom,
        number_of_bathroom: values.number_of_bathroom,
        number_of_balconies: values.number_of_balconies,
        parking: values.parking,
        email: values.email,
        phone: JSON.parse(sessionStorage.getItem("Customer"))?.phone || "",
        end_price: Number(values.end_price),
        start_price: Number(values.start_price),
        plot_size: values.plot_size,
      };
      // if (query.length > 3) {
      AxiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/create-palnning`,
        Object.assign(data, {
          location: query,
          _id: sessionStorage.getItem("CustomerID"),
        })
      )
        .then((response) => {
          sessionStorage.setItem("submitted", true);
          // navigate("/");
          setDrawer(true);
          setFormSwitch(false);
          resetForm("");
          setQuery("");
          setCardClick(true);
          setSpinner(false);
          handleOpen();
        })
        .catch((error) => {
          console.error(error);
          setSpinner(false);
          alert(error.response.data.msg);
        });
      // } else {
      //   alert("fill the location");
      // }

      // alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
      >
        <div className=" flex justify-center items-center flex-col ">
          <div
            className="p-3 bg-slate-50 my-3 h-screen startPlanningWidth"
            style={{ width: "90%", overflowY: "scroll" }}
          >
            <div>
              {/* <div className="btnCloseRefresh" onClick={() => handleClose1()}>
                <img src={closeBTnRefresh} />
              </div> */}
              <BlackCard
                paddingCustom={true}
                CloseBTN={true}
                navigate={navigate}
                imgs={CardLogo}
                handleClose1={handleClose1}
                tagtitle={
                  <>
                    <p
                      className="text-base font-bold text-white mt-10"
                      style={{ marginLeft: "30px" }}
                    >
                      Build Your <br /> Future <br />
                      <span>
                        <span className="text-[#F68F00]">home</span> with us
                      </span>
                    </p>
                  </>
                }
                tagtitleSub={
                  <p
                    className="px-5 mt-3 text-base font-bold font-[Play] text-[#F68F00] text-center"
                    onClick={() => handleClose1()}
                  >
                    Start planning now!
                  </p>
                }
              />
            </div>
            <div
              className="flex justify-end items-center"
              style={{
                position: "fixed",
                top: "0px",
                right: "5.2px",
                zIndex: "5000",
                width: "96%",
                padding: "4.5px",
              }}
            >
              {/* <div className=" rounded-full  h-fit  bg-[#E6E6E6] ">
              <CloseRoundedIcon
                style={{
                  width: "20px",
                  border: "1px solid gray",
                  borderRadius: "50%",
                  height: "25px",
                  width: "25px",
                }}
                onClick={() => setFormSwitch(false)}
              />
            </div> */}
            </div>
            <div>
              <div>
                <form onSubmit={formik.handleSubmit} className="mt-10">
                  <div className="lg:flex lg:justify-between lg:gap-10">
                    <div className=" lg:basis-1/2">
                      {/* -------------1st container ------------- */}
                      {/* <SearchLocation query={query} setQuery={setQuery} /> */}
                      <PlacesAutocompleteDropdown
                        query={query}
                        setQuery={setQuery}
                        style={{ cursor: "pointer" }}

                        // setAddresss={setAddress}
                      />
                      {/* {console.log(value, "vlaue")} */}
                      {/* <div className="did-floating-label-content mb-7">
                <input
                  className="did-floating-input inputField  border mt-2 font-[Lato]"
                  name="name"
                  placeholder=" "
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                <label className="did-floating-label font-[Lato]">
                  Enter Name
                </label>
                {formik.touched.name && formik.errors.name && (
                  <p className=" text-red-400">{formik.errors.name}</p>
                )}
              </div> */}
                      {/* <div className="did-floating-label-content mb-7">
                  <input
                    className="did-floating-input inputField  border mt-2 font-[Lato]"
                    name="email"
                    // id="email"
                    placeholder=" "
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  <label className="did-floating-label font-[Lato]">
                    Email
                  </label>
                  {formik.touched.email && formik.errors.email && (
                    <p className=" text-red-400">{formik.errors.email}</p>
                  )}
                </div> */}
                      <div className="mt-4">
                        <FormControl fullWidth>
                          <TextField
                            id="outlined-basic"
                            label="Email"
                            variant="outlined"
                            name="email"
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                            value={formik.values.email}
                            onChange={formik.handleChange}
                          />
                        </FormControl>
                      </div>
                      {/* <div className="did-floating-label-content mb-7">
                <input
                  className="did-floating-input  border mt-2 font-[Lato]"
                  placeholder=" "
                  name="phone"
                  type="number"
                  onKeyDown={(event) => {
                    let value = event.target.value;
                    if (value.length >= 10 && event.keyCode !== 8) {
                      event.preventDefault();
                    }
                  }}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                />
                {formik.touched.phone && formik.errors.phone && (
                  <p className=" text-red-400">{formik.errors.phone}</p>
                )}
                <label className="did-floating-label font-[Lato]">
                  Mobile Number
                </label>
              </div> */}
                      {/* <FormControl fullWidth> */}
                      {/* </FormControl> */}
                      <div className="mt-5">
                        <FormControl fullWidth>
                          <TextField
                            type="number"
                            id="outlined-basic"
                            label="Enter Plot Size (In Sq.Ft)"
                            variant="outlined"
                            name="plot_size"
                            error={
                              formik.touched.plot_size &&
                              Boolean(formik.errors.plot_size)
                            }
                            helperText={
                              formik.touched.plot_size &&
                              formik.errors.plot_size
                            }
                            value={formik.values.plot_size}
                            onChange={formik.handleChange}
                          />
                        </FormControl>
                      </div>
                      {/* <div className="did-floating-label-content mb-5">
                  <input
                    className="did-floating-input inputField  border mt-2 font-[Lato] "
                    name="plot_size"
                    type="number"
                    // id="plot_size"
                    placeholder=" "
                    value={formik.values.plot_size}
                    onChange={formik.handleChange}
                  />
                  <label className="did-floating-label font-[Lato]">
                    Enter Plot Size (In Sq.Ft)
                  </label>
                  {formik.touched.plot_size && formik.errors.plot_size && (
                    <p className=" text-red-400">{formik.errors.plot_size}</p>
                  )}
                </div> */}
                      <div className="mt-4">
                        <label
                          htmlFor="start_price "
                          className="  font-[Lato] mb-5"
                        >
                          Budget Range
                        </label>

                        <div className="flex justify-between  items-center mt-2 ">
                          <div className="did-floating-label-content">
                            <FormControl fullWidth>
                              <TextField
                                id="outlined-basic"
                                label="Min"
                                type="number"
                                variant="outlined"
                                name="start_price"
                                error={
                                  formik.touched.start_price &&
                                  Boolean(formik.errors.start_price)
                                }
                                helperText={
                                  formik.touched.start_price &&
                                  formik.errors.start_price
                                }
                                value={formik.values.start_price}
                                onChange={formik.handleChange}
                              />
                            </FormControl>
                            {/* <input
                      style={{ height: "45px" }}
                      className="did-floating-input inputField  border mt-2 budget_input font-[Lato]"
                      placeholder=" "
                      type="number"
                      name="start_price"
                      value={formik.values.start_price}
                      onChange={formik.handleChange}
                    />
                    <label
                      style={{ paddingTop: "6px" }}
                      className="did-floating-label font-[Lato]"
                    >
                      Min
                    </label> */}
                          </div>
                          <span className="mx-3 to font-[Lato]">to</span>
                          <div className="did-floating-label-content">
                            <FormControl fullWidth>
                              <TextField
                                id="outlined-basic"
                                label="Max"
                                type="number"
                                variant="outlined"
                                name="end_price"
                                // min="1"
                                // max="5"
                                error={
                                  formik.touched.end_price &&
                                  Boolean(formik.errors.end_price)
                                }
                                helperText={
                                  formik.touched.end_price &&
                                  formik.errors.end_price
                                }
                                value={formik.values.end_price}
                                onChange={formik.handleChange}
                                // onKeyDown={(event) => {
                                //   const inputValue = event.target.value;
                                //   if (inputValue === "" && event.key === "0") {
                                //     event.preventDefault();
                                //   }
                                // }}
                              />
                            </FormControl>
                            {/* <input
                      style={{ height: "45px" }}
                      className="did-floating-input inputField  border mt-2 font-[Lato]"
                      placeholder=" "
                      type="number"
                      name="end_price"
                      value={formik.values.end_price}
                      onChange={formik.handleChange}
                    />
                    <label
                      style={{ paddingTop: "6px" }}
                      className="did-floating-label font-[Lato]"
                    >
                      Max
                    </label> */}
                          </div>
                        </div>
                      </div>
                      {/* {formik.touched.end_price && formik.errors.end_price && (
                  <p className=" text-red-400">{formik.errors.end_price}</p>
                )} */}
                      <div className="w-full mt-4">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Property Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.property_type}
                            onChange={formik.handleChange}
                            name="property_type"
                            label="Select Property Type"
                            error={
                              formik.touched.property_type &&
                              Boolean(formik.errors.property_type)
                            }
                            helperText={
                              formik.touched.property_type &&
                              formik.errors.property_type
                            }
                          >
                            <MenuItem value="Residential">Residential</MenuItem>
                            <MenuItem value="Semi-residential">
                              Semi-residential
                            </MenuItem>
                            <MenuItem value="Commercial">Commercial</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <div className="did-floating-label-content mt-9">
                  <select
                    className="did-floating-select"
                    id="property_type"
                    name="property_type"
                    value={formik.values.property_type}
                    onChange={formik.handleChange}
                  >
                    <option
                      className="did-floating-label font-[Lato]"
                      disabled
                      value=""
                    >
                      {" "}
                      Select{" "}
                    </option>
                    <option value="Residential">Residential</option>
                    <option value="Semi-residential">Semi-residential</option>
                    <option value="Commercial">Commercial</option>
                  </select>
                  {formik.touched.property_type &&
                    formik.errors.property_type && (
                      <p className=" text-red-400">
                        {formik.errors.property_type}
                      </p>
                    )}
                  <label
                    href="property_type"
                    className="did-floating-label font-[Lato]"
                  >
                    Property Type
                  </label>
                </div> */}
                      <div className="w-full mt-4">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Construction Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.construction_type}
                            onChange={formik.handleChange}
                            name="construction_type"
                            label="Select Construction Type"
                            error={
                              formik.touched.construction_type &&
                              Boolean(formik.errors.construction_type)
                            }
                            helperText={
                              formik.touched.construction_type &&
                              formik.errors.construction_type
                            }
                          >
                            <MenuItem value="Traditional method">
                              Traditional method
                            </MenuItem>
                            <MenuItem value="Modern method">
                              Modern method
                            </MenuItem>
                            <MenuItem value="Commercial">Commercial</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <div className="did-floating-label-content">
                  <select
                    className="did-floating-select mt-8 font-[Lato]"
                    name="construction_type"
                    id="construction_type"
                    value={formik.values.construction_type}
                    onChange={formik.handleChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="Traditional method">
                      Traditional method
                    </option>
                    <option value="Modern method">Modern method</option>
                  </select>
                  <label
                    htmlFor="construction_type"
                    className="did-floating-label font-[Lato]"
                  >
                    Construction Type
                  </label>
                  {formik.touched.construction_type &&
                    formik.errors.construction_type && (
                      <p className=" text-red-400">
                        {formik.errors.construction_type}
                      </p>
                    )}
                </div> */}
                      {/* ------------ first container end here --------  */}
                    </div>
                    <div className=" lg:basis-1/2">
                      {/* ------------- 2nd container start from here -------------  */}
                      <div className="w-full mt-4 lg:mt-0">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Number of Floors
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.number_of_floor}
                            onChange={formik.handleChange}
                            name="number_of_floor"
                            label="Select Number of Floors"
                            error={
                              formik.touched.number_of_floor &&
                              Boolean(formik.errors.number_of_floor)
                            }
                            helperText={
                              formik.touched.number_of_floor &&
                              formik.errors.number_of_floor
                            }
                          >
                            <MenuItem value="1">G + 1</MenuItem>
                            <MenuItem value="2">G + 2</MenuItem>
                            <MenuItem value="3">G + 3</MenuItem>
                            <MenuItem value="4">G + 4</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <div className="did-floating-label-content mt-3">
                  <select
                    className="did-floating-select mt-8"
                    name="number_of_floor"
                    value={formik.values.number_of_floor}
                    onChange={formik.handleChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="1">G + 1</option>
                    <option value="2">G + 2</option>
                    <option value="3">G + 3</option>
                    <option value="4">G + 4</option>
                  </select>
                  <label
                    href="number_of_floor"
                    className="did-floating-label font-[Lato]"
                  >
                    Number of Floors
                  </label>
                  {formik.touched.number_of_floor &&
                    formik.errors.number_of_floor && (
                      <p className=" text-red-400">
                        {formik.errors.number_of_floor}
                      </p>
                    )}
                </div> */}
                      <div className="w-full mt-4 lg:mt-7">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Number of Bedrooms
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.number_of_badroom}
                            onChange={formik.handleChange}
                            name="number_of_badroom"
                            label="Select Number of Bedrooms"
                            error={
                              formik.touched.number_of_badroom &&
                              Boolean(formik.errors.number_of_badroom)
                            }
                            helperText={
                              formik.touched.number_of_badroom &&
                              formik.errors.number_of_badroom
                            }
                          >
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2"> 2</MenuItem>
                            <MenuItem value="3"> 3</MenuItem>
                            <MenuItem value="4"> 4</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <div className="did-floating-label-content mt-3">
                  <select
                    className="did-floating-select mt-8 font-[Lato]"
                    name="number_of_badroom"
                    id="number_of_badroom"
                    value={formik.values.number_of_badroom}
                    onChange={formik.handleChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                  </select>
                  <label
                    href="number_of_badroom"
                    className="did-floating-label font-[Lato]"
                  >
                    Number of Bedrooms
                  </label>
                  {formik.touched.number_of_badroom &&
                    formik.errors.number_of_badroom && (
                      <p className=" text-red-400">
                        {formik.errors.number_of_badroom}
                      </p>
                    )}
                </div> */}
                      <div className="w-full mt-4 lg:mt-5">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Number of Bathrooms
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.number_of_bathroom}
                            onChange={formik.handleChange}
                            name="number_of_bathroom"
                            label="Select Number of Bathrooms"
                            error={
                              formik.touched.number_of_bathroom &&
                              Boolean(formik.errors.number_of_bathroom)
                            }
                            helperText={
                              formik.touched.number_of_bathroom &&
                              formik.errors.number_of_bathroom
                            }
                          >
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2"> 2</MenuItem>
                            <MenuItem value="3"> 3</MenuItem>
                            <MenuItem value="4"> 4</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <div className="did-floating-label-content mt-3">
                  <select
                    className="did-floating-select mt-8"
                    name="number_of_bathroom"
                    id="number_of_bathroom"
                    value={formik.values.number_of_bathroom}
                    onChange={formik.handleChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <label
                    href="number_of_bathroom"
                    className="did-floating-label font-[Lato]"
                  >
                    Number of Bathrooms
                  </label>
                  {formik.touched.number_of_bathroom &&
                    formik.errors.number_of_bathroom && (
                      <p className=" text-red-400">
                        {formik.errors.number_of_bathroom}
                      </p>
                    )}
                </div> */}
                      <div className="w-full mt-4">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Number of Balconies
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.number_of_balconies}
                            onChange={formik.handleChange}
                            name="number_of_balconies"
                            label="Select Number of Balconies"
                            error={
                              formik.touched.number_of_balconies &&
                              Boolean(formik.errors.number_of_balconies)
                            }
                            helperText={
                              formik.touched.number_of_balconies &&
                              formik.errors.number_of_balconies
                            }
                          >
                            <MenuItem value="1">1</MenuItem>
                            <MenuItem value="2"> 2</MenuItem>
                            <MenuItem value="3"> 3</MenuItem>
                            <MenuItem value="4"> 4</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      {/* <div className="did-floating-label-content mt-3">
                  <select
                    className="did-floating-select mt-8 font-[Lato]"
                    name="number_of_balconies"
                    id="number_of_balconies"
                    value={formik.values.number_of_balconies}
                    onChange={formik.handleChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                  </select>
                  <label
                    href="number_of_balconies"
                    className="did-floating-label font-[Lato]"
                  >
                    Number of Balconies
                  </label>
                  {formik.touched.number_of_balconies &&
                    formik.errors.number_of_balconies && (
                      <p className=" text-red-400">
                        {formik.errors.number_of_balconies}
                      </p>
                    )}
                </div> */}
                      <div className="w-full mt-4">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">
                            Select Parking
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.parking}
                            onChange={formik.handleChange}
                            name="parking"
                            label="Select Parking"
                            error={
                              formik.touched.parking &&
                              Boolean(formik.errors.parking)
                            }
                            helperText={
                              formik.touched.parking && formik.errors.parking
                            }
                          >
                            <MenuItem value="Yes">Yes</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                          </Select>
                        </FormControl>
                      </div>

                      {/* -------------- 2nd container end -------------  */}
                    </div>
                  </div>

                  {/* <div className="did-floating-label-content mt-3">
                  <select
                    className="did-floating-select mt-8 font-[Lato]"
                    name="parking"
                    id="parking"
                    value={formik.values.parking}
                    onChange={formik.handleChange}
                  >
                    <option value="" disabled>
                      Select
                    </option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                  <label
                    href="parking"
                    className="did-floating-label font-[Lato]"
                  >
                    Parking
                  </label>
                  {formik.touched.parking && formik.errors.parking && (
                    <p className=" text-red-400">{formik.errors.parking}</p>
                  )}
                </div> */}
                  <div className="mt-10">
                    <div className=" ">
                      <div className="lg:flex lg:text-center lg:justify-center lg:flex-row-reverse lg:gap-10">
                        <div className="w-full lg:w-64">
                          <button
                            className="px-4 py-3  text-white w-full rounded-md text-base font-[Montserrat]"
                            type="submit"
                            style={{ backgroundColor: "#333333" }}
                            // onClick={() => {
                            //   handleOpen();
                            // }}
                          >
                            Submit{spinner && <Spinner />}
                          </button>
                        </div>

                        <div className="flex justify-end mt-5">
                          <p
                            className="Clearall cursor-pointer text-end font-[Montserrat]"
                            onClick={() => {
                              formik.resetForm("");
                              setQuery("");
                            }}
                          >
                            Clear all
                          </p>
                        </div>
                      </div>

                      <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{ background: "rgba(34, 30, 31, 0.9)" }}
                      >
                        <div className="w-full h-full flex items-center justify-center flex-col">
                          <ThankYOUCard
                            handleClose={handleClose}
                            img={SuccessIcone}
                            headupheadline={"heading"}
                            getback={"get back to me"}
                            setFormSwitch={setFormSwitch}
                          />
                        </div>
                      </Modal>

                      {/* <ThankYOUCard
                  Drawer={Drawer}
                  setDrawer={setDrawer}
                  cardClick={cardClick}
                /> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <button
        className="Start_Planningbtn py-2 px-3 text-center text-white font-[Play]"
        onClick={() => {
          trackEvent("Start Planning Now", {
            button_label: "Start Planning Now ",
          });
          if (sessionStorage.getItem("CustomerID")) {
            handleOpen1();
          } else {
            navigate("/OtpForm");
            Cookies.set("Planing", true, {
              expires: 1,
              path: "/",
            });
            Cookies.set("eventName", "Start Planning Now ", {
              expires: 1,
              path: "/",
            });
          }
        }}
      >
        Start Planning Now!
      </button>
    </>
  );
};

export default FormStartPlanning;
