import React, { useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import { AxiosInstance } from "./AxiosInstance";
import { useNavigate } from "react-router-dom";
import { Label } from "@mui/icons-material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Checkbox from "@mui/material/Checkbox";
import "./reusable.css";

const QuizCard = ({
  page,
  Option,
  title,
  handleCheckboxChange,
  setpage,
  objectID,
  checkedIds,
  nextApi,
  backApi,
  setDrawer,
  handleOpen,
  postMyQuiz,
  handleCheckboxChangeRadio,
}) => {
  const history = useNavigate();
  const [back, setBack] = useState(false);
  const [checkbox, setCheckbox] = useState(false);

  return (
    <div className="">
      <div className="bg-[#F3F2EF] p-5 mt-4    lg:w-[38rem] lg:m-auto">
        <p className="Question">{`${page} . ${title}`}</p>
        <div className="">
          <FormGroup>
            {Option?.map((val, i) => {
              if (val?.seleted) {
                checkedIds.push(val?._id);
              }
              return (
                <div
                  className=" bg-[#FFFFFF] py-5 px-2 mt-4 w-full"
                  key={val?._id}
                >
                  <div className=" text-center">
                    {val.images.length > 1 && (
                      <img
                        src={`${process.env.REACT_APP_SERVER_URL}${val.images}`}
                        alt="..."
                        className="text-center mx-auto"
                      />
                    )}

                    {page === 2 || page === 4 || page === 6 ? (
                      <div className=" flex items-center justify-center flex-row-reverse radio-item">
                        <label for={val?.option} className="m-10">
                          {val?.option}
                        </label>

                        {/* <Radio
                        name="fav_language"
                        // defaultChecked={val?.seleted}
                        value={val?._id}
                        id={val?.option}
                        onChange={(event) => (
                          handleCheckboxChangeRadio(event), setCheckbox(true)
                        )}
                        sx={{
                          color: "#fcc216",
                          "&.Mui-checked": {
                            color: "#fcc216",
                          },
                        }}
                      /> */}

                        <input
                          type="radio"
                          className="me-2 font-[Lato] cursor-pointer  "
                          // defaultChecked={val?.seleted}
                          value={val?._id}
                          id={val?.option}
                          name="fav_language"
                          onChange={(event) => (
                            handleCheckboxChangeRadio(event), setCheckbox(true)
                          )}
                        />
                      </div>
                    ) : (
                      <div className=" flex items-center gap-10 ">
                        {/* <label for={val?.option}>{val?.option}</label>
                        <input
                          type="radio"
                          className="me-2"
                          defaultChecked={val?.seleted}
                          value={val?._id}
                          id={val?.option}
                          onChange={(e) => (
                            console.log(e.target.value), handleCheckboxChange
                          )}
                        /> */}
                        <Checkbox
                          type="checkbox"
                          style={{
                            marginLeft: "10px",
                          }}
                          defaultChecked={val?.seleted}
                          value={val?._id}
                          onChange={(event) => (
                            handleCheckboxChange(event), setCheckbox(true)
                          )}
                          sx={{
                            color: "#fcc216",
                            "&.Mui-checked": {
                              color: "#fcc216",
                            },
                          }}
                        />

                        {/* <input
                        type="checkbox"
                        className="me-2 custom_checkbox font-[Lato] cursor-pointer"
                        style={{
                          backgroundColor: "red",
                          border: "4px solid blue !important",
                        }}
                        defaultChecked={val?.seleted}
                        value={val?._id}
                        onChange={(event) => (
                          handleCheckboxChange(event), setCheckbox(true)
                        )}
                      /> */}
                        <p className="vendorNameRFQ">{val?.option}</p>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </FormGroup>
          <div className=" flex justify-between items-center mt-4 space-x-4">
            {/* <button
            className="back w-1/2 py-4"
            // onClick={() => {
            //   setpage(page - 1);
            //   backApi(page - 1);
            //   if (page === 1) {
            //     history("/");
            //     setBack(true);
            //   }
            // }}
            // disabled={back}
          >
            Back
          </button> */}
            {/* <button
            className="Next w-1/2 py-4"
            // disabled={checkedIds.length === 0}
            onClick={() => {
              if (checkedIds.length !== 0) {
                if (page === 7) {
                  // setDrawer(true);
                  handleOpen();
                  postMyQuiz();
                  // history("/");
                } else {
                  postMyQuiz();
                }
              } else {
                alert("please Select the answer");
              }
            }}
            // disabled={checkedIds.length <= 0 || false}
          >
            Next
          </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizCard;
