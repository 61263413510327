import React from "react";
import "./reusable.css";
// import Carausel1 from "../../Assets/Carausel1.png";
// import Carausel2 from "../../Assets/Carausel2.png";
// card import
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";

const ArchitectsCard = ({ img, title, design, location }) => {
  return (
    <div className="mb-4" style={{ width: "300px", marginLeft: "10px" }}>
      <Card
        style={{
          width: "300px",
          boxShadow: "none",
          filter: "drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.09)",
        }}
      >
        <CardMedia sx={{ height: 270 }} image={img} title="..." />
        <CardContent>
          <p className="Simp_Himp mb-3">{title}</p>
          <div className=" flex justify-start  items-center mb-4">
            <p className="SqFt">{design}</p>
          </div>
          <Rating
            name="text-feedback"
            value={5}
            readOnly
            precision={0.5}
            emptyIcon={
              <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
            }
          />
          <p className="SqFt">{location}</p>
        </CardContent>
      </Card>
    </div>
  );
};

export default ArchitectsCard;
