import Navbar from "../Navbar/Navbar";
import FAQ from "../FAQ";
import Footer from "../Footer";
import { useFormik } from "formik";
import { SwipeableDrawer, useScrollTrigger } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import congratulation from "../../Assets/congratulation.png";
import { useNavigate } from "react-router-dom";
import { validationSchema } from "../../schemas/validation";
import { AxiosInstance, PostCountANDevent } from "./AxiosInstance";
import strongLogo from "../../Assets/strongLogo.svg";
import { useState } from "react";
import Spinner from "./Spinner";
import Modal from "@mui/material/Modal";
import { ThankYOUCard } from "../MethodStrongBuild";
// import { BlackCard } from "../ContactUs";
import CardLogo from "../../Assets/CardLogo.svg";
import Cookies from "js-cookie";
import useAnalytics from "../CustomHook/useAnalytics";
import BlackCard from "./BlackCard";

const FaqNav = () => {
  const { trackEvent } = useAnalytics();
  const [spinner, SetSpinner] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const history = useNavigate();
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      SetSpinner(true);
      AxiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/create-customer`,
        values
      )
        .then((response) => {
          SetSpinner(false);
          console.log(response.data);
          // sessionStorage.setItem("CustomerID", response.data?.result?._id);
          sessionStorage.setItem("submitted", true);
          resetForm("");
          // history("/");
          // setDrawer(true);
          // history("/quiz");
        })
        .catch((error) => {
          console.error(error);
          alert(error.response.data.msg);
        });
      // alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <div>
      <Navbar />
      <div>
        <div className="flex flex-col items-center justify-center gap-y-3 my-9  px-3">
          <div className="pt-4 lg:w-[90.5rem]">
            <BlackCard
              paddingCustom={true}
              navigate={navigate}
              imgs={CardLogo}
              tagtitle={
                <p className="font-bold text-2xl text-white mt-5 font-[Play] frequentlyQuestion">
                  Frequently <br /> asked <br />{" "}
                  <span className="text-[#E98700]">Questions</span>
                </p>
              }
              tagtitleSub={
                <p className="px-5 mt-3 text-base  font-[Play] text-[#E6E6E6] text-center ">
                  An attempt to solve your basic queries instantly.
                </p>
              }
            />
          </div>
        </div>
      </div>
      <div>
        <FAQ />
        {/* form contact details--  */}
        {/* <div>
          <form onSubmit={formik.handleSubmit} className="Form_container">
            <p className=" text-center">
              {<em className="text-[#E98700]">Still have a question?</em>{" "} }
              { <span className="text-[#717171]">
                Enter your contact details and we will answer them.
              </span> }
            </p>
            <div className="mx-6">
              { <div className="did-floating-label-content mb-7">
                  <input
                    className="did-floating-input inputField  border mt-2 "
                    name="name"
                    placeholder=" "
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  <label className="did-floating-label">Name</label>
                  {formik.touched.name && formik.errors.name && (
                    <p className=" text-red-400">{formik.errors.name}</p>
                  )}
                </div> }

              { <div className="did-floating-label-content mb-7">
                  <input
                    className="did-floating-input  border mt-2 "
                    placeholder=" "
                    name="phone"
                    type="number"
                    onKeyDown={(event) => {
                      let value = event.target.value;
                      if (value.length >= 10 && event.keyCode !== 8) {
                        event.preventDefault();
                      }
                    }}
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <p className=" text-red-400">{formik.errors.phone}</p>
                  )}
                  <label className="did-floating-label">Mobile Number</label>
                </div> }

              <div className="did-floating-label-content mb-7">
                  <input
                    className="did-floating-input inputField  border mt-2 "
                    name="email"
                    id="email"
                    placeholder=" "
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                  />
                  <label className="did-floating-label">Email</label>
                  {formik.touched.email && formik.errors.email && (
                    <p className=" text-red-400">{formik.errors.email}</p>
                  )}
                </div>
              <input
                className="inputField  border mt-3 px-2"
                name="name"
                placeholder="Enter Full Name..."
                type="text"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
              {formik.touched.name && formik.errors.name && (
                <p className=" text-red-400">{formik.errors.name}</p>
              )}
              <input
                className="inputField border mt-3  px-2"
                name="email"
                placeholder="Enter Email id"
                type="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email && (
                <p className=" text-red-400">{formik.errors.email}</p>
              )}
              <input
                className="inputField border mt-3  px-2"
                name="phone"
                placeholder="Enter Your Mobile Number"
                type="number"
                value={formik.values.phone}
                onKeyDown={(event) => {
                  let value = event.target.value;
                  if (value.length >= 10 && event.keyCode !== 8) {
                    event.preventDefault();
                  }
                }}
                onChange={formik.handleChange}
              />
              {formik.touched.phone && formik.errors.phone && (
                <p className=" text-red-400">{formik.errors.phone}</p>
              )}
              <button
                type="submit"
                className="saveContinue  px-4 py-2 w-full mt-5 relative flex justify-center items-center flex-row-reverse"
              >
                <div className="text-white ml-4">Submit</div>
                {spinner && <Spinner />}
              </button>
            </div>
          </form>
        </div> */}
      </div>

      <div className="p-4 text-center bg-[#F3F2EF]">
        <p className="text-xl text-[#494847] font-bold text-center font-[Play] lg:text-3xl">
          Sign-up with us to know more about the Strong
          <span className="text-[#E98700]">Build </span>
          method of construction
        </p>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ background: "rgba(34, 30, 31, 0.9)" }}
        >
          <div className="w-full h-full flex items-center justify-center flex-col">
            <ThankYOUCard handleClose={handleClose} />
          </div>
        </Modal>
        <button
          className="bg-[#333333] py-2 px-6 rounded-md text-white my-10 font-[Lato]"
          onClick={() => {
            trackEvent("faq", {
              button_label: "faq",
              from: "web",
            });
            if (sessionStorage.getItem("CustomerID")) {
              handleOpen();
              PostCountANDevent("faq");
            } else {
              history("/otpForm");
              Cookies.set("eventName", "faq", {
                expires: 1,
                path: "/",
              });
            }
          }}
        >
          Connect with our experts Now!
        </button>
      </div>

      {/* form contact details end ------  */}
      <Footer />
    </div>
  );
};

export default FaqNav;
