import React from "react";
import Planningcard from "../../Assets/Planningcard.png";
import Budgeting from "../../Assets/Budgeting.png";
import Reportcard from "../../Assets/Reportcard.png";
import Executioncard from "../../Assets/Executioncard.png";
const ProfessionalmanagementCard = () => {
  return (
    <div>
      <img src={Planningcard} alt="..." className=" w-96 object-contain " />
      {/* <Card style={{ border: "none" }}>
        <CardMedia
          style={{ border: "none" }}
          sx={{ height: 400 }}
          image={Planningcard}
          title="..."
        />
      </Card> */}
    </div>
  );
};

export const ProfessionalmanagementCard1 = () => {
  return (
    <div>
      <img src={Budgeting} alt="..." className=" w-96 object-contain " />
    </div>
  );
};
export const ProfessionalmanagementCard2 = () => {
  return (
    <div>
      <img src={Reportcard} alt="..." className=" w-96 object-contain " />
    </div>
  );
};

export const ProfessionalmanagementCard3 = () => {
  return (
    <div>
      <img src={Executioncard} alt="..." className=" w-96 object-contain " />
    </div>
  );
};

export default ProfessionalmanagementCard;
