import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import CardLogo from "../../Assets/CardLogo.svg";
// import { BlackCard } from "../ContactUs";
import { useFormik } from "formik";

import { useNavigate } from "react-router-dom";
import { validationSchema } from "../../schemas/validation";
import { AxiosInstance } from "./AxiosInstance";
import { object } from "yup";
import { ThankYOUCard } from "../MethodStrongBuild";
import SuccessIcone from "../../Assets/SuccessIcone.svg";
import { Modal } from "@mui/material";
import HeadsUp from "../../Assets/Process.png";
import Cookies from "js-cookie";
import BlackCard from "./BlackCard";
const OtpForm = ({ para, getback }) => {
  const history = useNavigate();
  const [otp, setOtp] = useState("");
  const [sendOTP, setSendOTP] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [nextBtn, setNextBtn] = useState(false);
  const [hello, setHello] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose1 = () => setOpen(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      // setSpinner(true);
      console.log("hi");

      AxiosInstance.post(`${process.env.REACT_APP_BASE_URL}/send-otp`, values)
        .then((response) => {
          console.log(response.data);
          setNextBtn(true);
          // setSpinner(false);

          // sessionStorage.setItem("CustomerID", response.data?.result?._id);
          // sessionStorage.setItem("submitted", false);
          setSendOTP(true);
        })
        .catch((err) => {
          console.error(err, "kjkjkjjhhgy");
          alert(err.response.data.msg);
        });
    },
  });

  useEffect(() => {
    if (otp.length === 4) {
      AxiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/verify-otp`,

        Object.assign(formik.values, {
          otp: Number(otp),
          from_register: Cookies.get("eventName"),
        })
      )
        .then((response) => {
          // setSpinner(false);
          setNextBtn(false);
          // history("/");
          handleOpen();

          sessionStorage.setItem("CustomerID", response?.data?.result?._id);
          sessionStorage.setItem(
            "Customer",
            JSON.stringify(response?.data?.result)
          );
          sessionStorage.setItem("submitted", false);
          setSendOTP(true);
          formik.resetForm("");
          // setDrawer(true);
          setHello(true);
        })
        .catch((err) => {
          console.error(err, "kjkjkjjhhgy");
          // alert(err.response.data.msg);
          if (err.response?.data?.msg) {
            setOtpError(true);
          }
        });
    }
  }, [otp]);

  return (
    <div className="Form_container pt-10 pb-10">
      <div className=" lg:flex lg:justify-between lg:items-center w-full ">
        <div className=" lg:ml-[36rem]">
          <Modal
            open={open}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            style={{ background: "rgba(34, 30, 31, 0.9)" }}
          >
            <div className="containerDesignw ">
              <ThankYOUCard
                handleClose={handleClose1}
                ViewClose={true}
                // img={HeadsUp}
                // headupheadline={"HEADS UP We're working on this section"}
                // getback={"We’ll get back to you soon!"}
              />
              {/* <ThanksHeadsUp handleClose={handleClose} ViewClose={true} /> */}
            </div>
          </Modal>
          <div className=" lg:w-[30rem] lg:mt-10">
            <div className="lg:p-0 lg:flex lg:justify-center lg:items-center lg:flex-col lg:border lg:border-[#e98700] lg:h-[37.5rem]  ">
              <div className="px-5  lg:w-[30rem] lg:mt-4">
                <BlackCard
                  paddingCustom={true}
                  CloseBTN={true}
                  navigate={history}
                  // handleClose={handleClose}
                  handleClose1={handleClose1}
                  imgs={CardLogo}
                  tagtitle={
                    <p className="text-white font-bold mt-9 font-[Play]">
                      Build Your <br /> Future <br />
                      <span>
                        {" "}
                        <span className="text-[#E98700]">home</span> with us
                      </span>
                    </p>
                  }
                  tagtitleSub={
                    <p className="px-5 mt-3 text-[#E98700] font-bold text-center font-[play]">
                      Get a Quote Now !
                    </p>
                  }
                />
              </div>

              <form
                onSubmit={formik.handleSubmit}
                className="Form_container mt-10 lg:m-0"
              >
                <div
                  style={{ marginTop: "1rem" }}
                  className=" space-y-4 px-4 bg-[#F3F2EF] pt-3"
                >
                  <label className="text-[#333] font-semibold">
                    Enter Name
                  </label>
                  <input
                    className="inputField border bg-white font-[Lato]"
                    placeholder="Enter Name..."
                    name="name"
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <p className=" text-red-400">{formik.errors.name}</p>
                  )}
                  <label
                    className="text-[#333] font-semibold font-[Lato"
                    style={{
                      marginTop: "40px",
                    }}
                  >
                    Enter Mobile Number
                  </label>
                  <input
                    className="inputField border  bg-white"
                    placeholder="Enter  Mobile Number..."
                    type="number"
                    name="phone"
                    // disabled={formik.values.phone.length === 10}
                    value={formik.values.phone}
                    onKeyDown={(event) => {
                      let value = event.target.value;
                      if (value.length >= 10 && event.keyCode !== 8) {
                        event.preventDefault();
                      }
                    }}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <p className=" text-red-400">{formik.errors.phone}</p>
                  )}
                  <div>
                    {sendOTP && (
                      <OtpInput
                        isInputNum={true}
                        shouldAutoFocus={true}
                        inputType="number"
                        value={otp}
                        onChange={setOtp}
                        separator={<span> </span>}
                        renderInput={(props) => (
                          <input type="number" min="1" {...props} />
                        )}
                        containerStyle={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        inputStyle={{
                          width: "4rem",
                          height: "3rem",

                          marginTop: "14px",
                          fontSize: "1rem",
                          borderRadius: 4,
                          border: `1px solid ${
                            otpError ? "red" : "rgba(0, 0, 0, 0.3)"
                          }`,
                        }}
                      />
                    )}
                  </div>
                  <div className="flex justify-between items-center">
                    {/* <p className="Resend">Change Mobile Number</p> */}
                    {/* <p className="Resend"></p> */}
                    {sendOTP && (
                      <button type="submit" className="Resend underline">
                        Resend OTP
                      </button>
                    )}
                  </div>
                  <button
                    type="submit"
                    className={`submitNExt w-full text-[#ffffff] py-4 text-base] ${
                      nextBtn ? "custom_hide" : ""
                    }`}
                    onClick={formik.handleSubmit}
                  >
                    Next
                  </button>
                </div>
              </form>
            </div>
          </div>

          {/* {hello && <ThankYOUCard />} */}
        </div>
      </div>
    </div>
  );
};

export default OtpForm;
