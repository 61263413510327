import axios from "axios";
import Cookies from "js-cookie";

export const AxiosInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "ngrok-skip-browser-warning": "true",
    authtoken:
      "YgbTVb9wXaQnXkW+1BiEud+KYRQdk1uf6D8azufXUcsnKR8GMlptqOqUCRNS7l5Y",
  },
});

export const PostCountANDevent = (eventName) => {
  AxiosInstance.get(
    `${
      process.env.REACT_APP_BASE_URL
    }/increase-count?_id=${sessionStorage.getItem(
      "CustomerID"
    )}&click_on=${eventName}`
  ).then((response) => {
    console.log(response);
  });
};
