import React from "react";
import Navbar from "./Navbar/Navbar";
import Footer from "./Footer";
import CardLogo from "../Assets/CardLogo.svg";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { NavLink, useNavigate } from "react-router-dom";
import { useState } from "react";
import BlackCard from "./Reusable/BlackCard";
const ContactUs = () => {
  return (
    <div>
      <Navbar />
      <>
        {/* <div className=" py-2  flex flex-col items-center justify-center gap-y-3 my-9  px-3">
          <div className="pt-4">
            <svg
              width="42"
              height="46"
              viewBox="0 0 42 46"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.1725 0.126007L0 20.6656V33.0987L19.4866 26.6282L19.4563 36.2527L0.03935 42.7291V45.0622H42V20.5505L21.1725 0.126007ZM39.6149 21.6343V31.7018L22.6647 25.9342V23.6897L22.5861 5.29414L39.6149 21.6343ZM2.32459 21.8735L19.42 5.33547V23.6986L2.32459 29.5371V21.8735ZM6.8648 42.7291L19.4351 38.4529V42.7291H6.8648ZM39.6694 42.7291H22.6526V28.849L39.6754 34.5694L39.6694 42.7291Z"
                fill="#070707"
              />
            </svg>
          </div>
          <h1 className="text-2xl font-bold text-[#494847] text-center">
            Have a <span className="text-[#E98700]">question</span> or an
            <span className="text-[#E98700]"> enquiry</span>?
          </h1>
          <p className="text-center text-[#717171] font-semibold">
            Get in touch with us and we will provide you with all possible
            assistance.
          </p>
        </div> */}
        <div className="p-3 lg:w-[90.5rem] lg:m-auto lg:my-10">
          <BlackCard
            CloseBTN={false}
            imgs={CardLogo}
            tagtitle={
              <p className="titleCardBlack haveAquestion">
                Have a <span className="text-[#e98700]">question</span> or an
                <span className="text-[#e98700]"> enquiry?</span>
              </p>
            }
            tagtitleSub={
              <p className="tagtitleSubblack px-5 mt-3 lg:p-0">
                Get in touch with us and we will provide you with all possible
                assistance.
              </p>
            }
          />
        </div>
        <div className="lg:px-20 lg:my-10">
          <div className="p-4 border bg-[#F3F2EF]">
            {/* <div className="flex flex-col gap-y-3 mb-8 ">
            <h4 className="font-semibold">Contact Us</h4>
            <a
              href="tel:8296241477"
              target="_blank"
              rel="noopener noreferrer"
              title="8296241477"
              className="land_footer_number"
            >
              <p className="text-[#717171] font-semibold">
                Phone{" "}
                <span className="underline italic ml-4">+91 82962 41477</span>
              </p>
            </a>

            <p className="text-[#717171] font-semibold ">
              Whatsapp
              <a
                href="https://wa.me/919741977477?text=I%27m%20interested%20in%20Cendrol%20Construction"
                target="_blank"
                title="8296241477"
              >
                <span className="underline ml-4 italic">+91 82962 41477</span>
              </a>
            </p>
            <a
              href="mailto:strong.build@gmail.com"
              title="strong.build@gmail.com"
            >
              <p className="text-[#717171] font-semibold">
                Email <span className="ml-4">admin@strongbuild.in</span>
              </p>
            </a>
          </div>
          <div className="mb-8">
            <h4 className="font-semibold">Follow Us</h4>
            <div className="flex items-center gap-2 my-4 ">
              <a href="https://www.facebook.com/cendrolbuild/" target="_blank">
                {" "}
                <svg
                  width="34"
                  height="35"
                  viewBox="0 0 34 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.2 8.01856C17.9734 8.01856 16.797 8.50803 15.9296 9.3793C15.0623 10.2506 14.575 11.4322 14.575 12.6644V15.251H12.1C11.976 15.251 11.875 15.3514 11.875 15.477V18.8923C11.875 19.0169 11.975 19.1183 12.1 19.1183H14.575V26.1248C14.575 26.2493 14.675 26.3508 14.8 26.3508H18.2C18.324 26.3508 18.425 26.2503 18.425 26.1248V19.1183H20.922C21.025 19.1183 21.115 19.048 21.14 18.9476L21.99 15.5323C21.9984 15.4989 21.9991 15.4641 21.992 15.4305C21.985 15.3969 21.9704 15.3653 21.9494 15.3382C21.9283 15.311 21.9014 15.2891 21.8707 15.274C21.84 15.2589 21.8062 15.251 21.772 15.251H18.425V12.6644C18.425 12.5622 18.445 12.4609 18.484 12.3665C18.5229 12.272 18.58 12.1862 18.652 12.1139C18.724 12.0416 18.8094 11.9843 18.9034 11.9452C18.9974 11.906 19.0982 11.8859 19.2 11.8859H21.8C21.924 11.8859 22.025 11.7855 22.025 11.6599V8.24458C22.025 8.12002 21.925 8.01856 21.8 8.01856H19.2Z"
                    fill="#717171"
                  />
                  <path
                    d="M33.75 17.1847C33.75 26.4788 26.2497 34.0112 17 34.0112C7.75029 34.0112 0.25 26.4788 0.25 17.1847C0.25 7.89055 7.75029 0.358093 17 0.358093C26.2497 0.358093 33.75 7.89055 33.75 17.1847Z"
                    stroke="#070707"
                    stroke-width="0.5"
                  />
                </svg>
              </a>
              <a href="https://twitter.com/Cendrol_in/status/" target="_blank">
                <svg
                  width="34"
                  height="35"
                  viewBox="0 0 34 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.283 25.4849C20.83 25.4849 24.959 19.1977 24.959 13.7553C24.959 13.5785 24.959 13.4017 24.951 13.2249C25.7551 12.6396 26.4489 11.9151 27 11.0853C26.2496 11.4184 25.4544 11.638 24.64 11.7372C25.498 11.2212 26.1405 10.4099 26.448 9.45401C25.6415 9.93462 24.7586 10.272 23.838 10.4515C23.4548 10.0402 22.9916 9.71237 22.4771 9.48826C21.9626 9.26416 21.4078 9.14856 20.847 9.14864C19.7583 9.14943 18.7143 9.58423 17.9445 10.3576C17.1746 11.1309 16.7418 12.1795 16.741 13.2731C16.741 13.5946 16.781 13.908 16.845 14.2133C15.2129 14.131 13.6163 13.7048 12.1588 12.9624C10.7013 12.22 9.41555 11.178 8.385 9.90402C7.86128 10.8127 7.70141 11.8874 7.93783 12.9101C8.17426 13.9329 8.78927 14.8269 9.658 15.4107C9.00708 15.3881 8.37059 15.2119 7.8 14.8964V14.9527C7.80088 15.9042 8.12873 16.8263 8.72816 17.5632C9.32759 18.3 10.1619 18.8064 11.09 18.9968C10.7383 19.0943 10.3749 19.1429 10.01 19.1414C9.745 19.1414 9.489 19.1173 9.24 19.0691C9.50164 19.8886 10.012 20.6053 10.6996 21.1187C11.3871 21.6321 12.2174 21.9165 13.074 21.932C11.6205 23.0797 9.82477 23.7024 7.976 23.6999C7.648 23.6999 7.32 23.6838 7 23.6436C8.87671 24.8466 11.0567 25.4855 13.283 25.4849Z"
                    fill="#717171"
                  />
                  <path
                    d="M33.75 17.1847C33.75 26.4788 26.2497 34.0112 17 34.0112C7.75029 34.0112 0.25 26.4788 0.25 17.1847C0.25 7.89055 7.75029 0.358093 17 0.358093C26.2497 0.358093 33.75 7.89055 33.75 17.1847Z"
                    stroke="#070707"
                    stroke-width="0.5"
                  />
                </svg>
              </a>

              <a href="https://www.instagram.com/cendrolbuild/" target="_blank">
                {" "}
                <svg
                  width="34"
                  height="35"
                  viewBox="0 0 34 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.5 7.39075H12.5C11.1081 7.39224 9.77358 7.94834 8.78933 8.93701C7.80509 9.92569 7.25149 11.2662 7.25 12.6644V21.7049C7.25149 23.1031 7.80509 24.4436 8.78933 25.4323C9.77358 26.421 11.1081 26.9771 12.5 26.9786H21.5C22.8919 26.9771 24.2264 26.421 25.2107 25.4323C26.1949 24.4436 26.7485 23.1031 26.75 21.7049V12.6644C26.7485 11.2662 26.1949 9.92569 25.2107 8.93701C24.2264 7.94834 22.8919 7.39224 21.5 7.39075ZM17 21.7049C16.11 21.7049 15.24 21.4398 14.4999 20.9431C13.7599 20.4464 13.1831 19.7405 12.8425 18.9145C12.5019 18.0885 12.4128 17.1797 12.5865 16.3028C12.7601 15.426 13.1887 14.6205 13.818 13.9884C14.4474 13.3562 15.2492 12.9257 16.1221 12.7513C16.995 12.5768 17.8998 12.6664 18.7221 13.0085C19.5443 13.3506 20.2471 13.93 20.7416 14.6733C21.2361 15.4167 21.5 16.2906 21.5 17.1847C21.4988 18.3831 21.0243 19.5322 20.1806 20.3796C19.337 21.227 18.1931 21.7037 17 21.7049ZM22.625 12.6644C22.4025 12.6644 22.185 12.5981 22 12.4739C21.815 12.3498 21.6708 12.1733 21.5856 11.9668C21.5005 11.7603 21.4782 11.5331 21.5216 11.3139C21.565 11.0947 21.6722 10.8933 21.8295 10.7352C21.9868 10.5772 22.1873 10.4696 22.4055 10.426C22.6238 10.3824 22.85 10.4047 23.0555 10.4903C23.2611 10.5758 23.4368 10.7207 23.5604 10.9065C23.684 11.0923 23.75 11.3108 23.75 11.5343C23.75 11.834 23.6315 12.1215 23.4205 12.3334C23.2095 12.5453 22.9234 12.6644 22.625 12.6644ZM20 17.1847C20 17.7807 19.8241 18.3633 19.4944 18.8589C19.1648 19.3545 18.6962 19.7407 18.1481 19.9688C17.5999 20.1969 16.9967 20.2566 16.4147 20.1403C15.8328 20.024 15.2982 19.737 14.8787 19.3155C14.4591 18.8941 14.1734 18.3571 14.0576 17.7726C13.9419 17.188 14.0013 16.5821 14.2284 16.0314C14.4554 15.4808 14.8399 15.0101 15.3333 14.679C15.8266 14.3479 16.4067 14.1712 17 14.1712C17.7956 14.1712 18.5587 14.4886 19.1213 15.0538C19.6839 15.6189 20 16.3854 20 17.1847Z"
                    fill="#717171"
                  />
                  <path
                    d="M33.75 17.1847C33.75 26.4788 26.2497 34.0112 17 34.0112C7.75029 34.0112 0.25 26.4788 0.25 17.1847C0.25 7.89055 7.75029 0.358093 17 0.358093C26.2497 0.358093 33.75 7.89055 33.75 17.1847Z"
                    stroke="#070707"
                    stroke-width="0.5"
                  />
                </svg>
              </a>
              <a
                href="https://www.youtube.com/channel/UCUpqY12z7-GlNroRG09nOSg"
                target="_blank"
              >
                <svg
                  width="34"
                  height="35"
                  viewBox="0 0 34 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M17 9.14864C17.855 9.14864 18.732 9.17074 19.582 9.2069L20.586 9.25511L21.547 9.31237L22.447 9.37364L23.269 9.43793C24.161 9.50652 25.0004 9.8889 25.6395 10.5178C26.2786 11.1467 26.6764 11.9819 26.763 12.8764L26.803 13.3033L26.878 14.2174C26.948 15.1646 27 16.1972 27 17.1847C27 18.1721 26.948 19.2047 26.878 20.152L26.803 21.0661C26.79 21.2127 26.777 21.3544 26.763 21.493C26.6764 22.3876 26.2784 23.2229 25.6391 23.8518C24.9999 24.4808 24.1602 24.8631 23.268 24.9314L22.448 24.9947L21.548 25.057L20.586 25.1142L19.582 25.1624C18.7218 25.2 17.861 25.2194 17 25.2207C16.139 25.2194 15.2782 25.2 14.418 25.1624L13.414 25.1142L12.453 25.057L11.553 24.9947L10.731 24.9314C9.83895 24.8628 8.99955 24.4804 8.36047 23.8515C7.72139 23.2226 7.32357 22.3875 7.237 21.493L7.197 21.0661L7.122 20.152C7.04554 19.1647 7.00484 18.1749 7 17.1847C7 16.1972 7.052 15.1646 7.122 14.2174L7.197 13.3033C7.21 13.1566 7.223 13.015 7.237 12.8764C7.32354 11.982 7.72122 11.147 8.36009 10.5181C8.99897 9.88923 9.83813 9.50674 10.73 9.43793L11.551 9.37364L12.451 9.31237L13.413 9.25511L14.417 9.2069C15.2775 9.16936 16.1387 9.14994 17 9.14864ZM15 14.7487V19.6206C15 20.0847 15.5 20.374 15.9 20.1429L20.1 17.707C20.1914 17.6542 20.2673 17.5781 20.3201 17.4864C20.3729 17.3947 20.4007 17.2906 20.4007 17.1847C20.4007 17.0787 20.3729 16.9747 20.3201 16.883C20.2673 16.7913 20.1914 16.7152 20.1 16.6623L15.9 14.2274C15.8088 14.1745 15.7053 14.1466 15.5999 14.1467C15.4945 14.1467 15.3911 14.1746 15.2998 14.2275C15.2086 14.2805 15.1329 14.3566 15.0802 14.4483C15.0276 14.5399 14.9999 14.6439 15 14.7498V14.7487Z"
                    fill="#717171"
                  />
                  <path
                    d="M33.75 17.1847C33.75 26.4788 26.2497 34.0112 17 34.0112C7.75029 34.0112 0.25 26.4788 0.25 17.1847C0.25 7.89055 7.75029 0.358093 17 0.358093C26.2497 0.358093 33.75 7.89055 33.75 17.1847Z"
                    stroke="#070707"
                    stroke-width="0.5"
                  />
                </svg>
              </a>
            </div>
          </div> */}

            <div className="lg:p-10 lg:w-full ">
              <h4 className="font-semibold lg:text-3xl  lg:w-full">
                Hapiness Guarantee
              </h4>
              <p className="text-sm text-[#717171] mt-2 lg:text-[26px] lg:leading-10">
                If you are not happy with our services, we’re not happy. We work
                tirelessly to make your custom house building experience
                delightful! That is why we offer the StrongBuild Happiness
                Guarantee.
              </p>
            </div>
          </div>
        </div>
      </>
      <Footer />
    </div>
  );
};

export default ContactUs;
