import "./App.css";
import "./index.css";
import HomePage from "./Component/HomePage";
import { Route, Routes } from "react-router-dom";
import MethodStrongBuild from "./Component/MethodStrongBuild";
import Quiz from "./Component/Quiz";
import AboutUs from "./Component/AboutUs";
import ContactUs from "./Component/ContactUs";
import FAQ from "./Component/FAQ";
import FaqNav from "./Component/Reusable/FaqNav";
import OtpForm from "./Component/Reusable/OtpForm";
import ComingSoon from "./ComingSoon";
// firebase
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN_KEY,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID_KEY,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET_KEY,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID_KEY,
  appId: process.env.REACT_APP_FIREBASE_APPID_KEY,
  measurementId: process.env.REACT_APP_MEASUREMENTID_KEY,
};
firebase.initializeApp(firebaseConfig);

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<HomePage />} />

        <Route path="/methods" element={<MethodStrongBuild />} />
        <Route path="/quiz" element={<Quiz />} />
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/faq" element={<FaqNav />} />
        <Route path="/OtpForm" element={<OtpForm />} />
        <Route path="/comingSoon" element={<ComingSoon />} />
        {/* <Route path="/faq" element={<FaqNav />} /> */}
      </Routes>
    </div>
  );
}

export default App;
