import React from "react";
import { useNavigate } from "react-router-dom";
import methodIcone from "../Assets/methodIcone.svg";
import methodIMGone from "../Assets/methodIMGone.png";
import method2 from "../Assets/method_two.png";
import method3 from "../Assets/method_three.png";
import Spinner from "./Reusable/Spinner";
import CardLogo from "../Assets/CardLogo.svg";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useFormik } from "formik";
import { validationSchema } from "../schemas/validation";
import { AxiosInstance, PostCountANDevent } from "./Reusable/AxiosInstance";
import { useScrollTrigger } from "@mui/material";
import { useState } from "react";
// import { BlackCard } from "./ContactUs";
import BlackCard from "./Reusable/BlackCard";
import Navbar from "./Navbar/Navbar";
import Modal from "@mui/material/Modal";
import HeadsUp from "../Assets/Process.png";
import successIcon from "../Assets/SuccessIcone.svg";

import OtpForm from "./Reusable/OtpForm";
import Cookies from "js-cookie";
import useAnalytics from "../Component/CustomHook/useAnalytics";

// thank YOU popUP Screen
export const ThankYOUCard = ({
  handleClose,
  ViewClose,
  img,

  handleOpen1,
}) => {
  const cookieValue = Cookies.get("FromQuz");
  const cookieValueDesign = Cookies.get("Planing");
  const history = useNavigate();
  return (
    <div className="bg-[#434344] m-5 p-5 rounded-md">
      <div className="rounded_circle bg-[#E6E6E6] float-right lg:bg-red- lg:rounded-full lg:w-7 lg:h-7">
        <CloseRoundedIcon
          style={{ width: "20px", color: "#969696" }}
          className="rounded-icon-web cursor-pointer"
          onClick={() => {
            if (ViewClose) {
              if (cookieValue) {
                history("/");
                Cookies.remove("FromQuz");
              } else if (cookieValueDesign) {
                history("/");
              } else {
                history("/");
              }
            } else {
              handleClose();
              history("/");
            }
          }}
        />
      </div>
      <img src={successIcon} className="mx-auto mt-12" alt="success" />
      <p className="selectingtext lg:bg-red- lg:my-5">
        Thank you for selecting your requirements.
      </p>
      <p className="soon">We’ll get back to you soon!</p>
    </div>
  );
};

export const ThankYouQuiz = ({ handleClose }) => {
  const history = useNavigate();

  return (
    <>
      <div className="bg-[#434344] m-5 p-5 rounded-md">
        <div className="rounded_circle bg-[#E6E6E6] float-right lg:border-4 lg:rounded-full lg:w-8 lg:h-8">
          <CloseRoundedIcon
            style={{
              width: "20px",
              margin: "1px",
              cursor: "pointer",
              // border: "3px solid red",
            }}
            className=" lg:w-24"
            onClick={() => {
              handleClose();
              history("/");
            }}
          />
        </div>
        <img src={successIcon} className="mx-auto mt-12 mb-2" alt="success" />
        <p className="selectingtext">
          Congratulations on successfully taking the
          <span className="text-[#F68F00]"> quiz</span> !
        </p>
        <div className="lg:text-center lg:flex lg:justify-center">
          <p className="soon mt-2 lg:w-96 lg:text-center">
            Please leave your contact information and we will send you your
            <span className="text-[#F68F00]"> results</span> at the earliest.
          </p>
        </div>
      </div>
    </>
  );
};
// // export const ThanksHeadsUp = ({ handleClose }) => {
//   const history = useNavigate();
//   return (
//     <>
//       <div className="bg-[#434344] m-5 p-5 rounded-md">
//         <div className="rounded_circle bg-[#E6E6E6] float-right">
//           <CloseRoundedIcon
//             style={{ width: "20px" }}
//             onClick={() => {
//               handleClose();
//               history("/");
//             }}
//           />
//         </div>
//         <img src={SuccessIcone} className="mx-auto mt-12 mb-2" alt="success" />
//         <p className="selectingtext">
//           <span className="text-[#F68F00]">HEADS UP</span>
//         </p>
//         <p className="text-white">We're working on this section</p>
//         <p className="text-[#c0c0c0]">We'all get back to you soon!</p>
//       </div>
//     </>
//   );
// };

const MethodStrongBuild = () => {
  const history = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { trackEvent } = useAnalytics();

  return (
    <>
      <Navbar />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className=" flex justify-end items-center p-4">
          {/* <div className=" rounded-full  h-fit  bg-[#E6E6E6] ">
            
            <CloseRoundedIcon
              style={{ width: "20px" }}
              onClick={() => history("/")}
            />
          </div> */}
        </div>
        {/* <p onClick={() => history("/")}>hi</p> */}
        {/* <div className=" w-100 flex justify-center mt-5">
        <img src={methodIcone} alt=".." />
      </div>
      <p className="latest_cutting mt-2">
        Strong<span style={{ color: "#E98700" }}>Build</span> uses the latest
        cutting edge methods of construction
      </p> */}
        <div className="p-3  lg:w-[90.5rem] lg:m-auto">
          <BlackCard
            CloseBTN={false}
            imgs={CardLogo}
            tagtitle={
              <p className="titleCardBlack lg:mt-10 methodsWeb">
                Sign-up with us to know more about the Strong
                <span className="text-[#e98700]">Build</span> method of
                construction
              </p>
            }
            tagtitleSub={
              <p className="tagtitleSubblack px-5 mt-3 lg:text-center">
                Get in touch and we will provide you with all possible
                assistance.
              </p>
            }
          />
        </div>

        <div className=" lg:grid lg:grid-cols-2 lg:px-10 lg:py-5 ">
          <div style={{ marginTop: "3rem" }}>
            <div className="relative">
              <img
                src={methodIMGone}
                alt="..."
                className="w-1/2 object-contain lg:w-3/5"
              />
              <div className="absolute bg-[#FFFFFF] p-5 w-60 top-[.6rem] border rounded-md right-4 lg:h-[26rem] lg:w-[22rem] lg:left-80">
                <p className="CONSTRUCTION lg:text-sm construction_head ">
                  PRE-CAST CONSTRUCTION
                </p>
                <p className="CONSTRUCTION_sub construction_web">
                  In precast concrete building components, including walls,
                  slabs, staircases, columns and beams, are produced at a plant
                  or casting yard under controlled manufacturing conditions. We
                  use both 3D Volumetric construction and precast concrete
                  construction.{" "}
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "3rem" }}>
            <div className="relative">
              {/* <div className="absolute bg-[#FFFFFF] p-5 w-60 top-[.6rem] border rounded-md right-4">
              <p className="CONSTRUCTION">PRE-FAB CONSTRUCTION</p>
              <p className="CONSTRUCTION_sub">
                In our method, we employ dry constructing methodology and
                prefabrication. We make use of LGSF steel framework and steel
                bars. Our Prefab buildings are quickly-built, strong yet
                lightweight and are immensely suited for modular home
                construction.
              </p>
            </div> */}
              <img
                src={method2}
                alt="..."
                className="w-1/2 object-contain ml-48 lg:w-3/5 lg:ml-72"
              />
              <div className="absolute bg-[#FFFFFF] p-5 w-60 top-[.6rem] border rounded-md right-[120px] lg:h-[26rem] lg:w-[22rem] lg:mr-48">
                <p className="CONSTRUCTION construction_head ">
                  PRE-FAB CONSTRUCTION
                </p>
                <p className="CONSTRUCTION_sub construction_web">
                  In our method, we employ dry constructing methodology and
                  prefabrication. We make use of LGSF steel framework and steel
                  bars. Our Prefab buildings are quickly-built, strong yet
                  lightweight and are immensely suited for modular home
                  construction.
                </p>
              </div>
            </div>
          </div>
          <div style={{ marginTop: "3rem" }}>
            <div className="relative">
              <img
                src={method3}
                alt="..."
                className="w-1/2 object-contain lg:w-3/5"
              />
              <div className="absolute bg-[#FFFFFF] p-5 w-60 top-[.6rem] border rounded-md right-4 lg:h-[26rem] lg:w-[22rem]">
                <p className="CONSTRUCTION construction_head">
                  POD CONSTRUCTION
                </p>
                <p className="CONSTRUCTION_sub construction_web">
                  It involves constructing small, modular units that can be
                  combined to create larger structure. With StrongBuild in your
                  team, you can create a unique and modern home that meets all
                  of your needs and exceeds your expectations.
                </p>
              </div>
            </div>
          </div>
          <div className=" lg:mt-12 lg:flex lg:flex-col lg:justify-center lg:items-center">
            <p className="latest_cutting my-2 px-2 lg:w-[37rem] ">
              Sign-up with us to know more about the Strong
              <span style={{ color: "#E98700" }}>Build </span> method of
              construction
            </p>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              style={{ background: "rgba(34, 30, 31, 0.9)" }}
            >
              <div className="containerDesignw ">
                <ThankYOUCard handleClose={handleClose} />
                {/* <OtpForm /> */}
              </div>
            </Modal>
            <button
              className="border bg-[#333333] text-white shadow-md py-2 rounded-md px-3 mx-12 mb-5 font-[Lato] lg:mt-10"
              onClick={() => {
                trackEvent("Strong_Build_Method_of_Construction", {
                  button_label: "Strong_Build_Method_of_Construction",
                });
                if (sessionStorage.getItem("CustomerID")) {
                  handleOpen();
                  PostCountANDevent("Strong_Build_Method_of_Construction");
                } else {
                  history("/otpForm");
                  Cookies.set(
                    "eventName",
                    "Strong_Build_Method_of_Construction",
                    {
                      expires: 1,
                      path: "/",
                    }
                  );
                }
              }}
            >
              Connect with our experts Now!
            </button>
          </div>
        </div>

        {/* <form onSubmit={formik.handleSubmit} className="Form_container py-10">
        <div
          style={{ marginTop: "1rem" }}
          className=" space-y-4 px-4 bg-[#F3F2EF] pt-3"
        >
          <p className="title_form">Enter your contact details</p>
          <input
            className="inputField border bg-white"
            placeholder="Enter Name..."
            name="name"
            type="text"
            value={formik.values.name}
            onChange={formik.handleChange}
          />
          {formik.touched.name && formik.errors.name && (
            <p className=" text-red-400">{formik.errors.name}</p>
          )}
          <input
            className="inputField border bg-white"
            placeholder="Enter Email..."
            type="email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
          />
          {formik.touched.email && formik.errors.email && (
            <p className=" text-red-400">{formik.errors.email}</p>
          )}
          <input
            className="inputField border  bg-white"
            placeholder="Enter Your Mobile Number..."
            type="number"
            name="phone"
            value={formik.values.phone}
            onKeyDown={(event) => {
              let value = event.target.value;
              if (value.length >= 10 && event.keyCode !== 8) {
                event.preventDefault();
              }
            }}
            onChange={formik.handleChange}
          />
          {formik.touched.phone && formik.errors.phone && (
            <p className=" text-red-400">{formik.errors.phone}</p>
          )}
          <button className="bg-[#E98700] px-4 py-2 w-full rounded-sm relative flex justify-center items-center flex-row-reverse saveContinue">
            <div className="text-white">Submit</div>
            {spinner && <Spinner />}
          </button>
        </div>
      </form> */}
      </div>
    </>
  );
};

export default MethodStrongBuild;
