import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Dropzone from "react-dropzone";
import FileIcone from "../../Assets/FileIcone.svg";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useFormik } from "formik";
import { AxiosInstance } from "./AxiosInstance";
import LensIcon from "@mui/icons-material/Lens";
import CloseIcon from "@mui/icons-material/Close";
import { validationSchema } from "../../schemas/validation";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import { ThankYOUCard } from "../MethodStrongBuild";
import Cookies from "js-cookie";
import useAnalytics from "../CustomHook/useAnalytics";

const DesignForm = () => {
  const history = useNavigate();
  const { trackEvent } = useAnalytics();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [radioButton, setradioButton] = useState("female");
  const [uploadStateChange, setuploadStateChange] = useState(false);
  const [CheckboxFirstFloor, setCheckboxFirstFloor] = useState(false);
  const [CheckboxSecondFloor, setCheckboxSecondFloor] = useState(false);
  const [CheckboxThirdFloor, setCheckboxThirdFloor] = useState(false);
  const [valueInput, setvalueInput] = useState("");
  const [fileData, setfileData] = useState([]);
  console.log(fileData, "filedaat");
  const [spinner, setSpinner] = useState(false);
  const handleImage = (acceptedFiles) => {
    setfileData(acceptedFiles);

    setuploadStateChange(true);
    // let OnlyFive = [];
    // OnlyFive =
    //   acceptedFiles?.length > 4 ? acceptedFiles.slice(0, 5) : acceptedFiles;
    // setBluckfile(OnlyFive);
    // setfileName(OnlyFive);
    // ValidationFileType(OnlyFive);
  };
  const handleChange = (event) => {
    setradioButton(event.target.value);
    // console.log(event.target.value);
  };
  // const validationSchema = yup.object({
  //   name: yup.string("Enter your name").required("name is required"),
  //   email: yup
  //     .string("Enter your email")
  //     .email("Enter a valid email")
  //     .required("email is required"),
  //   phone: yup.string("Enter your phone").required("phone is required"),
  // });

  const deletFile = (i) => {
    let filesone = [...fileData];
    filesone.splice(i, 1);
    setfileData(filesone);
  };

  const submitAPi = () => {
    setSpinner(true);
    var formdata = new FormData();
    // formdata.append(
    //   "file",
    //   fileInput.files[0],
    //   "/C:/Users/KITS/Downloads/0.png"
    // );
    fileData?.forEach((image) => {
      formdata.append("files", image);
    });

    formdata.append(
      "require",
      radioButton === "Others" ? valueInput : radioButton
    );

    formdata.append("floor_plan", CheckboxFirstFloor);
    formdata.append("3D_plan", CheckboxSecondFloor);
    formdata.append("2D_plan", CheckboxThirdFloor);
    formdata.append("customer_id", sessionStorage.getItem("CustomerID"));

    const headers = {
      "content-type": "multipart/form-data",
    };
    AxiosInstance.post(
      `${process.env.REACT_APP_BASE_URL}/create-design-plan`,

      formdata,
      { headers }
      // Object.assign(values, {
      //   start_price: fromRagner,
      //   end_price: TORagner,
      // })
    )
      .then((response) => {
        setSpinner(false);
        // sessionStorage.setItem("CustomerID", response.data?.result?._id);
        sessionStorage.setItem("submitted", true);
        handleOpen();

        setCheckboxFirstFloor(false);
        setCheckboxSecondFloor(false);
        setCheckboxThirdFloor(false);
        setradioButton("");
        setfileData([]);
        setvalueInput("");
        // handleClose1();
      })
      .catch((error) => {
        setSpinner(false);
        console.error(error);
        alert(error.response.data.msg);
      });
  };
  return (
    <>
      <Modal
        open={open1}
        onClose={handleClose1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ background: "rgba(34, 30, 31, 0.9)" }}
      >
        <div className="containerDesignw ">
          <ThankYOUCard
            handleClose={handleClose}
            ViewClose={false}
            handleOpen1={handleOpen1}
          />
        </div>
      </Modal>
      <div className="">
        <button
          className="px-6 py-2 border bg-[#333333] text-white rounded-md z-50 font-[Montserrat] font-semibold lg:px-20 lg:text-lg lg:mt-2"
          onClick={() => {
            trackEvent("Already have a design", {
              button_label: "Already have a design",
            });
            if (sessionStorage.getItem("CustomerID")) {
              handleOpen1();
            } else {
              history("/otpForm");
              Cookies.set("FRomDESIGN", true, {
                expires: 1,
                path: "/",
              });
              Cookies.set("eventName", "Already have a design", {
                expires: 1,
                path: "/",
              });
            }
          }}
        >
          Start Now!
        </button>
      </div>
      <Modal
        open={open1}
        onClose={() => handleClose1()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="containerDesign  ">
          <div className=" flex justify-end items-center p-4">
            <div className=" rounded-full  h-fit  bg-[#E6E6E6] lg:w-7 lg:h-7 ">
              <CloseRoundedIcon
                className="rounded-icon-web cursor-pointer"
                style={{ width: "20px" }}
                onClick={() => handleClose1()}
              />
            </div>
          </div>
          <div className=" lg:flex lg:gap-10 lg:p-10 lg:ml-72 ">
            <div className=" lg:basis-[40%]">
              {" "}
              <p className="Thankyou text-center mb-2 lg:text-base lg:text-[#717171] lg:mb-10 lg:pt-2">
                Already have a design?
              </p>
              <div className=" flex   items-center px-5">
                <div className=" w-1/2 border   h-48 flex items-center justify-center flex-col lg:flex-row ">
                  <FormGroup>
                    <div className="flex items-center justify-center flex-col h-full ">
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) => {
                              setCheckboxFirstFloor(event.target.checked);
                              console.log(
                                CheckboxFirstFloor,
                                CheckboxSecondFloor,
                                CheckboxThirdFloor
                              );
                            }}
                            style={{
                              marginRight: "7px",
                            }}
                          />
                        }
                        label="Floor Plan"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) =>
                              setCheckboxSecondFloor(event.target.checked)
                            }
                          />
                        }
                        label="2 D Design"
                      />

                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(event) =>
                              setCheckboxThirdFloor(event.target.checked)
                            }
                          />
                        }
                        label="3 D Design"
                      />
                    </div>
                  </FormGroup>
                </div>{" "}
                <Dropzone onDrop={handleImage}>
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps({
                        className:
                          " w-1/2 dotted_border h-48 bg-[#F9E1BF] flex justify-center items-center flex-col  ",
                      })}
                    >
                      <div className="justify-center items-center flex-col ">
                        <div className=" border-dotted  ">
                          <img className="mx-auto" src={FileIcone} alt="..." />
                          <p className="Upload text-center mt-2">
                            Upload your file here
                          </p>
                          <p className="Uploadtype text-center mt-2">
                            File types: .gif, jpeg or .png only
                          </p>
                        </div>
                        <input name="bulkdata" multiple {...getInputProps()} />

                        <div className="uplode_file_btn_container  w-full mx-auto  flex mt-4 ">
                          <button
                            className=" mx-auto uplode_file_btn px-4 py-2  rounded "
                            type="button"
                          >
                            Browse File
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
              {fileData.length > 0 && (
                <div className="  bg-[#FBEFDE] mt-3 mx-3">
                  {fileData.length > 0 && (
                    <ul style={{ padding: "10px" }}>
                      {fileData?.map((filess, i, arr) => (
                        <div className=" flex justify-center items-center">
                          <LensIcon
                            className="me-1"
                            style={{
                              color: " #000000",
                              width: "10px",
                            }}
                          />
                          <li className="fileName">{filess?.path}</li>
                          <CloseIcon
                            fontSize="small"
                            className="ms-1"
                            onClick={() => {
                              deletFile(i);
                            }}
                            style={{ color: " #000000" }}
                          />
                        </div>
                      ))}
                    </ul>
                  )}
                </div>
              )}
            </div>

            <div className=" lg:basis-[40%] lg:h-96">
              <p className="Thankyou text-center my-4">
                Require our help with?
              </p>
              <div className="bg-[#FFFFFF] mx-4 rounded-md p-5 lg:border lg:mt-8 lg:h-60">
                <FormControl className="w-full">
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue={radioButton}
                    onChange={handleChange}
                    name="radio-buttons-group"
                  >
                    <div className=" flex justify-between items-center w-full space-x-5">
                      <div className=" w-1/2 rounded-sm px-3 py-2 border">
                        <FormControlLabel
                          value="Construction"
                          control={<Radio color="warning" />}
                          label="Construction"
                          name="Construction"
                        />
                      </div>
                      <div className=" w-1/2 rounded-sm px-4 py-2 border">
                        <FormControlLabel
                          value="Re-Design"
                          control={<Radio color="warning" />}
                          label="Re-Design"
                          name="Re-Design"
                        />
                      </div>
                    </div>
                    <div className="w-full rounded-sm px-4 py-2 border mt-4">
                      <FormControlLabel
                        value="Others"
                        control={<Radio color="warning" />}
                        label="Others"
                      />
                    </div>
                    {radioButton === "Others" && (
                      <input
                        className="inputField border mt-3"
                        name="text"
                        value={valueInput}
                        onChange={(e) => setvalueInput(e.target.value)}
                        placeholder="Please enter details..."
                        type="Enter Name"
                      />
                    )}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="w-full h-full flex items-center justify-center flex-col bg_thankyou">
              <ThankYOUCard handleClose={handleClose} />
            </div>
          </Modal>

          <button
            disabled={fileData.length === 0}
            onClick={() => {
              if (sessionStorage.getItem("CustomerID")) {
                submitAPi();
              } else {
                history("/otpForm");
              }
            }}
            className="bg-[#E98700] mx-auto mt-28 w-52 px-4 py-2 text-white  rounded-sm flex justify-center items-center flex-row-reverse saveContinue lg:mt-0 lg:border lg:w-44"
          >
            <div className="text-white ml-4">Submit</div>
            {spinner && <Spinner className="text-white" />}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default DesignForm;
